/**
 * File responsible for all the UI and actions for Sales>Credits page - `/app/sales/credits`.
 */

import { Button, Col, Modal, Row, Select, Typography } from 'antd';
import {
    difference,
    every,
    filter,
    forEach,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    map,
    remove,
    capitalize
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withAccountingSystemHandler } from '../../components/common/AccountingSystemHandler';
import ActionBar from '../../components/common/ActionBar';
import { customFieldIndicator } from '../../components/common/FilterBar';
import FontAwesome from '../../components/common/FontAwesome';
import { withPageViewHandler } from '../../components/common/PageViewHandler';
import SpecialisedReportsDrawer from '../../components/common/SpecialisedReportsDrawer';
import VirtualizedList from '../../components/common/VirtualizedList';
import CreditItemComponent from '../../components/sales/CreditItemComponent';
import CreditReconcileReportPanel from '../../components/sales/CreditReconcileReportPanel';
import { getRolePermissions } from '../../store/roles/sagas';
import {
    CREDITS_PAGE,
    CUSTOM_FIELD_TYPES,
    PAGE_NAMES_FOR_VIEW,
} from '../../config/tableAndPageConstants';
import { CompanyIdAttribute } from '../../constants/authUserAttributes';
import { SpecialisedReports } from '../../constants/common';
import {
    creditsSortByOptions,
    creditsStateFilterOptions,
    getCreditsFilterBarFilters,
} from '../../constants/creditsSortAndFilters';
import {
    creditsSummaryPageQuery,
    creditsSummaryQueryName,
    defaultPageSizeForReport,
} from '../../constants/downloadToExcel';
import { ApplicationState } from '../../store';
import {
    downloadToExcelAction,
    getCustomFieldsFilterListByTypes,
} from '../../store/common/actions';
import {
    CompanyCustomFieldConfigure,
    CompanyUserRole,
} from '../../store/companies/types';
import {
    getCreditsReconciliationReportRequestAction,
    getCreditsRequestAction,
    setCreditSelectedIdRequestAction,
    updateCreditsFiltersAction,
    updateCreditsSortByAndStateAction,
    updateCreditsTableFilterAction,
    getCreditCustomFieldsForCompanyRequestAction,
    createCreditManuallyRequestAction,
    deleteCreditManuallyRequestAction,
    getCreditDataRequestAction,
} from '../../store/credits/actions';
import { getCustomerFirstLetterValuesRequestAction } from '../../store/customers/actions';
import { initialState } from '../../store/credits/reducer';
import { getCreditSelectedId } from '../../store/credits/sagas';
import {
    CreditVM,
    CreditsState,
    GetCreditsRequestPayload,
} from '../../store/credits/types';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getCurrentUser } from '../../store/users/sagas';
import {
    computeTableScroll,
    downloadToExcelModalResponseHandler,
    emptyPredefinedFilterOnAppliedFilters,
    getTranslatedText,
    getUsedSortByOptionsIfCloud,
    populateDownloadToExcelModalDisplayMessage,
    populateDownloadToExcelModalTitle,
    removeAppliedFiltersForApiRequest,
    replaceInstancesOfCustomerString,
    useCompanyFlagValue,
} from '../../utils/commonFunctions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import CreateCreditManuallyPanel from '../../components/sales/CreateCreditManuallyPanel';
import CreateCreditItemManuallyDrawerContentWrapper from '../../components/sales/CreateCreditItemManuallyDrawerContent';
import { confirmModalCancelText, confirmModalOkText } from '../../config/config';
import CreateScheduledReportDrawer from '../../components/common/CreateScheduledReportDrawer';
import { AtbViewType } from '../../constants/settings';
// import CreditAllocateToInvoicePanel from '../../components/sales/CreditAllocateToInvoicePanel';

const CreditItemDetailsDrawerComponent = lazy(
    () => import('../../components/sales/CreditItemDetailsDrawerComponent')
);
const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);
const FilterBar = lazy(() => import('../../components/common/FilterBar'));

const { Title } = Typography;
const { Option } = Select;

let lastSelectedCompanyId: string | null = null;
let resetTableScroll = false;
let isRefetching = false;
let fetchCreditsRequired = false;
let skipListenToPreconfiguredFilter = false;
const tablePageSize = CREDITS_PAGE.pageSize;
const pageName = PAGE_NAMES_FOR_VIEW.CREDITS_PAGE;
let initialTableFilter: undefined | string = undefined;

interface IProps {
    readonly isUsingCloudImportType: boolean;
    readonly handlePageViewSelection: (
        tableFilterValue: string | undefined,
        applyFiltersFunction: Function,
        actionBarRefCurrent?: any,
        pageName?: string
    ) => void;
}
const CreditsManagementPage: React.FC<IProps> = ({
    isUsingCloudImportType,
    handlePageViewSelection,
}: IProps) => {
    const customerLabel = useSelector(getCustomerUILabel);
    
    const dispatch = useDispatch();

    const selectedId = useSelector(getCreditSelectedId);
    const currentUser = useSelector(getCurrentUser);
    const rolePermissions = useSelector(getRolePermissions);

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();

    const [customFieldsFilterList, setCustomFieldsFilterList] = useState<
        CompanyCustomFieldConfigure[]
    >([]);

    const [customFieldValuesList, setCustomFieldValuesList] = useState<DynamicObject[]>(
        []
    );

    const [customerValuesOption, setCustomerValuesList] = useState<DynamicObject[]>(
        []
    );

    const [creditDetailsDrawer, setCreditDetailsDrawer] = useState<{
        visible: boolean;
        activeTab: undefined | string;
    }>({
        visible: false,
        activeTab: undefined,
    });

    const creditsState: CreditsState = useSelector(
        (state: ApplicationState) => state.credits
    );

    const [creditsTableFilter, setCreditsTableFilter] = useState<
        string | undefined
    >(
        isEqual(creditsState.filters, initialState.filters)
            ? initialState.tableFilter
            : creditsState.tableFilter
    );

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );
    const isCalendarView = useCompanyFlagValue(AtbViewType.CalendarView);

    const selectedUserCompanyLoading: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.loading
    );

    const IsSearchCustomFieldsByCheckBox: boolean = get(
        selectedUserCompany,
        'Company.IsSearchCustomFieldsByCheckbox'
    );
    
    const SupportCashAllocation = get(selectedUserCompany, 'Company.SupportCashAllocation');

    const [tableFilters, setTableFilters] = useState<any>(creditsState.filters);

    const [createScheduleReportPayload, setCreateScheduleReportPayload] = useState<any>();

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: string[];
        unselectedRowKeys: string[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
    });

    const [actionBarItemsState, setActionBarItemsState] = useState<{
        editButtonLoading: boolean;
    }>({
        editButtonLoading: false,
    });

    const [editAllowed, setEditAllowed] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

    /**
     * Function to get used sort options
     */
    const getCreditsSortByOptions = () => {
        return getUsedSortByOptionsIfCloud(
            creditsSortByOptions,
            isUsingCloudImportType
        );
    };

    /**
     * Function for checking if sort value is in options and set the first one if not.
     */
    const checkSortBySelectedIfAvailable = () => {
        if (!selectedUserCompany) return;
        const usedSortByOptions = getCreditsSortByOptions();
        const usedSortValues = map(
            usedSortByOptions,
            (sortOpt) => sortOpt.value
        );
        forEach(
            customFieldsFilterList,
            ({ Type, FieldName }: CompanyCustomFieldConfigure) => {
                usedSortValues.push(
                    `${customFieldIndicator}${Type}--${FieldName}`
                );
            }
        );
        if (!includes(usedSortValues, creditsState.sortBy)) {
            setTimeout(() => {
                const newSortValues = {
                    ...tableSortState,
                    sortBy: initialState.sortBy,
                };

                updateTableSortStateObject(newSortValues);
                dispatch(updateCreditsSortByAndStateAction(newSortValues));
            }, 200);
        }
    };

    useEffect(checkSortBySelectedIfAvailable, [
        selectedUserCompany,
        creditsState.sortBy,
    ]);

    /**
     * Function that checks if there are filters from redux for this page.
     * Returns boolean.
     */
    const getHasFiltersOnRedux = () => {
        let hasFiltersOnRedux = false;
        forEach(creditsState.filters, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasFiltersOnRedux = true;
                return false; // terminates the foreach
            }
        });

        return hasFiltersOnRedux;
    };

    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        addCommentDrawer: boolean;
        allSelected: boolean;
        editDrawer: boolean;
        downloadToExcel: boolean;
        specialisedReportsPanel: boolean;
        allocateToInvoiceDrawer: boolean;
        reconcileReportDrawer: boolean;
        createCreditManuallyDrawer: boolean;
        editCreditManuallyDrawer: boolean;
        createScheduledReport: boolean;
    }>({
        filterBar: getHasFiltersOnRedux(),
        filterEllipsis: false,
        addCommentDrawer: false,
        allSelected: false,
        editDrawer: false,
        downloadToExcel: false,
        specialisedReportsPanel: false,
        allocateToInvoiceDrawer: false,
        reconcileReportDrawer: false,
        createCreditManuallyDrawer: false,
        editCreditManuallyDrawer: false,
        createScheduledReport: false
    });

    const [tableSortState, setTableSortState] = useState<{
        sortBy: string;
        creditState: string;
        sortAscending: boolean;
    }>({
        sortBy: creditsState.sortBy,
        creditState: creditsState.creditState,
        sortAscending: creditsState.sortAscending,
    });

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(creditsState, 'pageData.currentPage', 0)
    );

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const payload: GetCreditsRequestPayload = {
            filters: readyAllFiltersSelected(),
            sortBy: creditsState.sortBy,
            sortAscending: creditsState.sortAscending,
            pageSize,
            currentPage: currentPage,
            tableFilter: creditsTableFilter,
            creditState: creditsState.creditState,
            isUsingCloudImportType,
        };

        return payload;
    };

    /**
     * Function that handles fetching of credits (calls an action that triggers an API call).
     * @param currentPage - page dependent on scroll
     * @param pageSize - number of items in page
     */
    const fetchCredits = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        if (isEmpty(selectedUserCompany)) return;
        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;
        dispatch(getCreditsRequestAction(payload));
    };

    /**
     * Function called when more data from list is requested
     * by scrolling down.
     */
    const handleFetch = () => {
        if (
            isUndefined(initialTableFilter) ||
            isEmpty(selectedUserCompany) ||
            creditsState.loading
        )
            return;

        if (!creditsState.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchCredits(nextPage);
    };

    /**
     * Function that fetches the custom fields filter list
     */
    const getCustomFieldsFilterList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getCustomFieldsFilterListByTypes(
                    [CUSTOM_FIELD_TYPES.CREDIT, CUSTOM_FIELD_TYPES.CUSTOMER], // Filters are sorted based on the orders of types here and Number property
                    (response: any) => {
                        if (response.IsSuccess) {
                            setCustomFieldsFilterList(response.CustomFields);
                        }
                    }
                )
            );
        }
    };

    /**
     * Function that fetches the custom fields values
     */
    const getCustomFieldsValueList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');
        let customFieldsFilterOptions: DynamicObject[] = [];

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getCreditCustomFieldsForCompanyRequestAction(
                    selectedCompanyId,
                    async (response: any) => {
                        if(response){
                            if (response.IsSuccess) {
                                customFieldsFilterOptions = await getCustomFieldsOptionsByKey(customFieldsFilterOptions,response.CustomFieldValues);
                                setCustomFieldValuesList(customFieldsFilterOptions)
                            }
                        }                        
                    }                    
                )
            ); 
        }          
    };

    /**
     * Function for populating the custom field options by key used (affected when UsingCustomerWorkflow is changed).
     * @param workflowKey
     * @param actionFilterOptions
     */
    const getCustomFieldsOptionsByKey = async (
        customFieldsFilterOptions: DynamicObject[],
        customFieldValuesList: DynamicObject[]
    ) => {
        forEach(
            customFieldsFilterList,
            (customFieldsOption,index) => {                
                const customFieldName = get(customFieldsOption, 'FieldName');
                const customFieldLists = customFieldValuesList.filter(x => x.Name == customFieldName && x.Value != null);

                const childCustomFieldValues = map(customFieldLists, (cfl,index) => ({
                    label: cfl.Value,
                    value: cfl.Value,
                }));

                customFieldsFilterOptions.push({
                    Number:index,
                    Type: get(customFieldsOption, 'Type'),
                    FieldName: get(customFieldsOption, 'FieldName'),
                    children: childCustomFieldValues,
                    filterLoading: creditsState.loading
                });
            }
        );

        return customFieldsFilterOptions;
    };

    useEffect(getCustomFieldsFilterList, [selectedUserCompany]);
    useEffect(getCustomFieldsValueList, [customFieldsFilterList]);

    /**
     * Function called when page loads, changes in filters, and sort options,
     * or company is changed.
     */
    const callFetchCredits = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            if (lastSelectedCompanyId !== selectedCompanyId) {
                lastSelectedCompanyId = selectedCompanyId;
                resetAllSelectedRowKeys();
                checkAllTableFiltersOnCompanySwitch();
                resetTableScrollAndPageData();
            }
            if (!selectedUserCompanyLoading) fetchCredits(0);
        }
    };

    /**
     * Function for comparing component and redux state then setting the correct values.
     */
    const checkAllTableFiltersOnCompanySwitch = () => {
        const { filters, sortBy, sortAscending, creditState } = creditsState;
        if (!isEqual(filters, tableFilters)) {
            setTableFilters(filters);
        }

        if (
            sortBy !== tableSortState.sortBy ||
            sortAscending !== tableSortState.sortAscending ||
            creditState !== tableSortState.creditState
        ) {
            updateTableSortStateObject({
                sortBy,
                sortAscending,
                creditState,
            });
        }
    };

    useEffect(callFetchCredits, [
        creditsState.sortBy,
        creditsState.creditState,
        creditsState.sortAscending,
        creditsState.filters,
        selectedUserCompany,
    ]);

    // on Unmount
    useEffect(() => {
        return () => {
            lastSelectedCompanyId = null;
            skipListenToPreconfiguredFilter = true;
        };
    }, []);

    const selectedKeysName = 'Id';

    /**
     * Function that checks if the row is selected.
     * Specifically if `Select all` is clicked and the credit data is updated,
     * it marks all the checkbox depending on the selected value.
     */
    const checkRowSelectionState = () => {
        if (showConditions.allSelected) {
            const selectedKeys = difference(
                map(creditsState.data, selectedKeysName),
                tableRowSelection.unselectedRowKeys
            );

            updateTableRowSelection({
                selectedRowKeys: selectedKeys,
            });
        }
    };

    useEffect(checkRowSelectionState, [creditsState.data]);

    /**
     * Action Bar Functions
     */
    /**
     * Function that updates the redux table filters and state table filters.
     * @param filter - dropdown view value
     * @param refetch - boolean indicator if fetching of items is to be called
     */
    const changeCreditsTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    allSelected: false,
                    filterBar: true,
                });
            }
        }

        if (filter !== creditsState.tableFilter) {
            skipListenToPreconfiguredFilter = false;
        }

        setCreditsTableFilter(filter);
        resetTableScrollAndPageData();
        if (refetch && filter === creditsState.tableFilter) {
            handleCreditFilterRefresh();
        } else {
            dispatch(updateCreditsTableFilterAction(filter));
        }
    };

    /**
     * Function responsible for refetching credits data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchCredits(0);
    };

    const getCreditsFilterPayload = () => {
        const { allExcept, keysToUse, filterObject } =
            getSelectedCreditsValues();

        const payload = {
            filter: filterObject,
            creditIds: keysToUse,
            excludeCredits: allExcept,
        };

        return payload;
    };

    const deleteCreditManuallyResponseModal = ({
        IsSuccess,
        Messages
    }: ResponseModalObject) => {
        setDeleteLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: map(
                    Messages || [],
                    (message: string, index: number) => (
                        <div key={index}>{getTranslatedText(message)}</div>
                    )
                ),
                onOk: () => {
                    refetchListAndResetScroll();
                },
                okText: getTranslatedText("OK")
            });
        } else {
            let errorMessageContent: any = getTranslatedText('Failed to delete credits!');
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }
            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                okText: getTranslatedText("OK")
            });
        }
    };

    const onDeleteClick = () => {
        Modal.confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText('Confirm'),
            content: getTranslatedText('Are you sure you want to delete the selected credits?'),
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
            onOk: () => {
                setDeleteLoading(true);
                const payload = getCreditsFilterPayload();
                dispatch(deleteCreditManuallyRequestAction({
                    ...payload,
                    callback: deleteCreditManuallyResponseModal
                }));
            }
        });
    };

    /**
     * Function called when refresh button is clicked (upper left near table filter dropdown).
     */
    const handleCreditFilterRefresh = () => {
        fetchCreditsRequired = true;
        skipListenToPreconfiguredFilter = false;
        resetAllSelectedRowKeys();
        refetchListAndResetScroll();
    };

    /**
     * Listener function called when the table filter value for the dropdown at the upper left has been changed.
     */
    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;
        const { tableFilter } = creditsState;
        if (tableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchCreditsRequired) {
                fetchCredits(0);
                fetchCreditsRequired = false;
            }
        } else {
            handlePageViewSelection(
                tableFilter,
                applyFilters,
                actionBarRef.current,
                pageName
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [creditsState.tableFilter]);

    /**
     * Function that populates the credit table filter (upper left).
     * @param menu
     */
    const populateCreditFilterSelectDropdownRender = (
        menu: React.Component
    ) => (
        <div>
            {menu}
            {/* <Divider className="action-bar-divider" />
            <div
                className="pa-12 cursor-p"
                onMouseDown={() => console.log('Add Custom View Clicked')}
            >
                Add custom view
            </div> */}
        </div>
    );

    /**
     * Function that populates the label for button (Select all or Deselect all).
     */
    const populateSelectDeselectAllLabel = () => {
        const allDataLoaded = creditsState.pageData.hasNextPage === false;

        if (
            !isEmpty(creditsState.data) &&
            ((tableRowSelection.unselectedRowKeys.length === 0 &&
                showConditions.allSelected) ||
                (allDataLoaded &&
                    creditsState.data.length ===
                        tableRowSelection.selectedRowKeys.length))
        ) {
            return 'Deselect all';
        } else {
            return 'Select all';
        }
    };

    /**
     * Function called when `Select all` button is clicked.
     */
    const selectAllRows = () => {
        const selectAllCondition =
            isEmpty(tableRowSelection.selectedRowKeys) ||
            tableRowSelection.selectedRowKeys.length < creditsState.data.length;

        updateShowConditionsObject({
            allSelected: selectAllCondition,
        });

        if (selectAllCondition) {
            const idKeys: string[] = map(creditsState.data, selectedKeysName);

            updateTableRowSelection({
                selectedRowKeys: idKeys,
                unselectedRowKeys: [],
            });
        } else {
            updateTableRowSelection({
                selectedRowKeys: [],
            });
        }

        setEditAllowed(false);
    };

    /**
     * Function called when sort order button is clicked (ascending or descending).
     * @param sortAscending - boolean - true for ascending and false for descending
     */
    const changeSortOrder = async (sortAscending: boolean) => {
        await resetTableScrollAndPageData();
        dispatch(
            updateCreditsSortByAndStateAction({
                ...tableSortState,
                sortAscending,
            })
        );
    };

    const downloadDisabled = creditsState.loading || isEmpty(creditsState.data);

    /**
     * Function for populating the upper section for the ellipsis popover.
     */
    const populateFilterEllipsisPopoverTitle = () => (
        <div className="pop-action-title">
            <Button
                type="link"
                disabled={creditsState.loading}
                onClick={() => {
                    setShowConditions((prevState: { filterBar: boolean }) => ({
                        ...showConditions,
                        filterEllipsis: false,
                        filterBar: !prevState.filterBar,
                    }));
                }}
            >
                <FontAwesome icon={['fas', 'filter']} className="mr-10" />
                {getTranslatedText('Filter')}
            </Button>
            <br />
            <Button
                type="link"
                disabled={creditsState.loading}
                onClick={() => {
                    setTableSortState((prevState: any) => {
                        const sortOrder = !prevState.sortAscending;
                        changeSortOrder(sortOrder);
                        return {
                            ...prevState,
                            sortAscending: sortOrder,
                        };
                    });
                }}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        `sort-amount-${
                            creditsState.sortAscending ? 'down' : 'up-alt'
                        }`,
                    ]}
                    className="mr-10"
                />
                {getTranslatedText('Change sort order')}
            </Button>
            <br />
            <Button
                type="link"
                onClick={() => downloadToExcelHandler()}
                disabled={downloadDisabled}
            >
                <FontAwesome
                    icon={['fas', 'cloud-download-alt']}
                    className="mr-10"
                />
                {getTranslatedText('Download to Excel')}
            </Button>
            <br />
            <Button
                type="link"
                onClick={createScheduledReportHandler}
                // disabled={downloadDisabled}
            >
                    <FontAwesome
                        icon={['far', 'calendar']}
                        className={`icon calendar`}
                    />
                    <FontAwesome
                        icon={['fas', 'clock']}
                        className={`icon clock`}
                    />
                <span className='icon-button-wrapper'>{getTranslatedText("Create Scheduled Report")}</span>
            </Button>
            <br />
            <Button
                type="link"
                onClick={() => {
                    updateShowConditionsObject({
                        filterEllipsis: false,
                        specialisedReportsPanel: true
                    })
                }}
                disabled={downloadDisabled}
            >
                <FontAwesome
                    icon={['fas', 'cloud-download-alt']}
                    className="mr-10"
                />
                {getTranslatedText('Specialised Reports')}
            </Button>
        </div>
    );

    /**
     * Function called when Download to Excel button is clicked.
     */
    const downloadToExcelHandler = (specializedReportType?: string) => {
        updateShowConditionsObject({
            downloadToExcel: true,
            filterEllipsis: false,
        });
        const variables = getDownloadReportVariables();

        const payload = {
            Query: creditsSummaryPageQuery,
            OperationName: creditsSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.CREDITS_PAGE,
            SpecializedReport: specializedReportType
        };
        dispatch(downloadToExcelAction(payload, downloadToExcelModalResponse));
    };

      /**
     * Function called when create schedule report is closed.
     * @param resetChanges
     * @param saveChanges
     * @param containerRef
     */
      const onCreateScheduledReportEnd = (
        containerRef?: any
    ) => {
        updateShowConditionsObject({
            createScheduledReport: false,
        });
    };

    /**
     * Function called when Create Scheduled report button is clicked.
     */
    const createScheduledReportHandler = () => {
        updateShowConditionsObject({
            createScheduledReport: true,
            filterEllipsis: false,
        });

        const variables = getDownloadReportVariables();

        const payload = {
            Query: creditsSummaryPageQuery,
            OperationName: creditsSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.CREDITS_PAGE,
            title: "Credits",
            Type: 1
        };

        setCreateScheduleReportPayload(payload);
    };

    /**
     * Callback function after calling the download to excel api.
     * @param param0 - response with type `ResponseModalObject`
     */
    const downloadToExcelModalResponse = ({
        IsSuccess,
        Messages,
    }: ResponseModalObject) => {
        updateShowConditionsObject({
            downloadToExcel: false,
            filterEllipsis: false,
        });
        downloadToExcelModalResponseHandler(IsSuccess, Messages);
    };

    /**
     * Function that populates the lower section of the ellipsis popover.
     */
    const populateFilterEllipsisPopoverContent = () => {
        return (
            <div className="pop-action-content">
                <div className="mb-10">
                    <span>{getTranslatedText('Sort by')}</span>
                </div>
                <div>
                    <Select
                        onChange={(sortBySelected: string) =>
                            updateTableSortStateObject({
                                sortBy: sortBySelected,
                            })
                        }
                        value={tableSortState.sortBy}
                    >
                        {map(
                            getCreditsSortByOptions(),
                            ({
                                label,
                                value,
                            }: {
                                label: string;
                                value: string;
                            }) => {
                                const labelUsed =
                                    replaceInstancesOfCustomerString(
                                        label,
                                        customerLabel
                                    );
                                    
                                return (
                                    <Option key={value} value={value}>
                                        {getTranslatedText(labelUsed)}
                                    </Option>
                                );
                            }
                        )}
                        {map(
                            customFieldsFilterList,
                            ({
                                Type,
                                Number: CFNumber,
                                FieldName,
                            }: CompanyCustomFieldConfigure) => (
                                <Option
                                    key={Type + CFNumber + FieldName}
                                    value={`${customFieldIndicator}${Type}--${FieldName}`}
                                >
                                    {FieldName}
                                </Option>
                            )
                        )}
                    </Select>
                </div>
                <br />
                <div className="mb-10">
                    <span>{getTranslatedText('State of credits')}</span>
                </div>
                <div>
                    <Select
                        onChange={(stateSelected: string) =>
                            updateTableSortStateObject({
                                creditState: stateSelected,
                            })
                        }
                        value={tableSortState.creditState}
                    >
                        {map(
                            creditsStateFilterOptions,
                            ({ label, value }: any) => (
                                <Option key={value} value={value}>
                                    {label}
                                </Option>
                            )
                        )}
                    </Select>
                </div>
                <br />
                <div className="ta-right">
                    <Button
                        type="primary"
                        disabled={
                            creditsState.loading ||
                            (tableSortState.sortBy === creditsState.sortBy &&
                                tableSortState.creditState ===
                                    creditsState.creditState)
                        }
                        onClick={applySortedByAndState}
                    >
                        {getTranslatedText('Apply')}
                    </Button>
                </div>
            </div>
        );
    };

    /**
     * Filter Bar Functions
     */
    /**
     * Function that updates the table filters state, also the table filters redux state.
     * Called when `Apply filters` button is clicked.
     * @param filters
     * @param fromFilterBar - boolean indicator if called from Apply filters button in FilterBar component
     */
    const applyFilters = async (
        filters?: GetCreditsRequestPayload['filters'],
        fromFilterBar?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    creditsTableFilter,
                    fromFilterBar,
                    () => {
                        changeCreditsTableFilter('');
                    }
                );
            }
        }

        let appliedFilters: any = filters || initialState.filters;
        const savedSortState = get(appliedFilters, 'tableSortState');
        if (savedSortState) {
            setTableSortState(savedSortState);
            applySortedByAndState(savedSortState);
            delete appliedFilters.tableSortState;
        }

        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(updateCreditsFiltersAction(appliedFilters));

        await resetAllSelectedRowKeys();
    };

    /**
     * Function that sets the value of the dropdown filter next to refresh button to it's initial state.
     */
    const setTableFilterToInitialState = () => {
        if (creditsState.tableFilter !== initialTableFilter) {
            setCreditsTableFilter(initialTableFilter);

            if (!isUndefined(initialTableFilter))
                changeCreditsTableFilter(initialTableFilter);

            resetAllSelectedRowKeys();
        }
    };

    /**
     * Function called when `Apply` button is clicked inside the ellipsis popover,
     * also hides the ellipsis popover after `Save` is clicked.
     * @param savedValues - optional param - used when a specific tableSortState value is passed.
     */
    const applySortedByAndState = async (savedValues?: any) => {
        await resetTableScrollAndPageData();
        const usedValues = get(savedValues, 'sortBy')
            ? savedValues
            : tableSortState;
        await dispatch(updateCreditsSortByAndStateAction(usedValues));
        await updateShowConditionsObject({
            filterEllipsis: false,
        });
    };

    /**
     * Common function that manipulates the state object for showConditions state.
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions((prevState) => ({
            ...prevState,
            ...showConditionObject
        }));
    };

    /**
     * Common function that updates the tableRowSelection state.
     * @param selectionObject - must conform to tableRowSelection object
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Common function for updating the action bar items state.
     * @param itemStateObject - must conform to actionBarItemsState object
     */
    const updateActionBarItemsState = (itemStateObject: {}) => {
        setActionBarItemsState({
            ...actionBarItemsState,
            ...itemStateObject,
        });
    };

    /**
     * Credit Details Functions
     */
    /**
     * Function called when credit details drawer is closed.
     */
    const closeCreditDetailsDrawer = () => {
        setCreditDetailsDrawer({
            visible: false,
            activeTab: undefined,
        });
    };

    /**
     * Table Functions
     */
    /**
     * Common function called for updating table sort state.
     * @param tableSortStateObject  - must conform to tableSortState object
     */
    const updateTableSortStateObject = (tableSortStateObject: {}) => {
        setTableSortState({
            ...tableSortState,
            ...tableSortStateObject,
        });
    };

    /**
     * Function called when checkbox is clicked (either checked/unchecked) - will call onRowSelect.
     * @param record - the data for each row (with additional `key` property to identify the row)
     */
    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
    };

    /**
     * Function called when row is clicked. Responsible for showing the drawer.
     * @param record - data for each row
     * @param activeTab - the tab key to be shown upon showing the drawer
     */
    const onRowClick = (record: DynamicObject, activeTab?: string) => {
        dispatch(
            setCreditSelectedIdRequestAction(get(record, 'Id'), () => {
                setCreditDetailsDrawer({
                    visible: true,
                    activeTab,
                });
            })
        );
    };

    /**
     * Function triggered when after checkbox is clicked. Responsible for setting the state of selected
     * or unselected rows.
     * @param record - table row data record
     * @param selected - boolean if checked/unchecked
     * @param selectedRows - list of keys selected previously
     * @param nativeEvent
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: string[],
        nativeEvent: Event | boolean
    ) => {
        // nativeEvent overridden
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];
        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );
        } else {
            unselectedRowKeys = showConditions.allSelected
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];
        }

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
        });

        let isEditAllowed = false;

        if (selectedRowKeys.length === 1) {
            const creditItem: DynamicObject = creditsState.data.filter(item => item.Id === selectedRowKeys[0]);
           
            if (creditItem[0].CreatedType === "Manually" && creditItem[0].State === "Open") {
                isEditAllowed = true;
            }
        }

        setEditAllowed(isEditAllowed);
    };

    /**
     * Function using for get selected credit
     */
    const getSelectedCredit = () => {
        let credit = undefined;

        if (tableRowSelection.selectedRowKeys.length === 1) {
            credit = creditsState.data.find(item => item.Id === tableRowSelection.selectedRowKeys[0]) as CreditVM;

            if(credit) {
                credit.UsePercentage = credit.Percentage > 0;
            }
        }

        return credit;
    };

    /**
     * Function triggered when action bar items with drawer/popover is clicked.
     * @param name - name of popover item
     * @param condition - optional condition whether to show the popover/drawer
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                let visibilityCondition = visible;
                if (
                    name === 'editDrawer' ||
                    name === 'addCommentDrawer' ||
                    name === 'allocateToInvoiceDrawer' ||
                    name === 'reconcileReportDrawer' ||
                    name === 'createCreditManuallyDrawer' ||
                    name === 'editCreditManuallyDrawer'
                ) {
                    visibilityCondition = !showConditions[name];
                    updateShowConditionsObject({
                        [name]: visibilityCondition,
                    });
                } else {
                    updateShowConditionsObject({
                        [name]: visible,
                    });
                }
            }
        };
    };

    /**
     * Reset Functions
     */
    /**
     * Function for resetting the table scroll and sets current page to initial state.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
     * Function for resetting all selected and unselected row keys in the state.
     * Also resets some of the action bar items state
     * and the allselected indicator for `Select all` button.
     */
    const resetAllSelectedRowKeys = () => {
        // reset Selected Row Keys after change role success
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
        });
        updateActionBarItemsState({
            editButtonDisabled: true,
        });
        updateShowConditionsObject({
            allSelected: false,
        });
    };

    /**
     * Function called when closing the filter bar.
     * Responsible for hiding the filter bar.
     * Also resets all the table state filters applied to initial state.
     */
    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

    /**
     * Function that manipulates all the table filter selected.
     * Like adding additional filters required in the payload before sending it to API.
     */
    const readyAllFiltersSelected = () => {
        return {
            ...creditsState.filters,
        };
    };

    /**
     * Function for getting the values needed when connecting to API.
     * Like the selected keys, filter object, and boolean indicator if
     * the selected keys are to be excluded from search or included.
     */
    const getSelectedCreditsValues = () => {
        let allExcept = false;
        let keysToUse = [...tableRowSelection.selectedRowKeys];

        if (showConditions.allSelected) {
            allExcept = true;
            keysToUse = [...tableRowSelection.unselectedRowKeys];
        }
        const filters = readyAllFiltersSelected();
        const filterObject = {
            ...filters,
            CreditState: tableSortState.creditState,
        };

        return {
            allExcept,
            keysToUse,
            filterObject,
        };
    };

    const deleteAllowed = (() => {
        const { allExcept } = getSelectedCreditsValues();
        if (allExcept) {
            return true;
        }
        const credits = filter(creditsState.data, c => includes(tableRowSelection.selectedRowKeys, c.Id));
        return !isEmpty(credits) && every(credits, c => get(c, 'CreatedType') === 'Manually');
    })();

    // /**
    //  * Function called when credit data is updated (vip status)
    //  * either in Edit button (batch)
    //  * or the vip status update inside the Credit details drawer.
    //  */
    // const refetchCreditsListAfterEditing = () => {
    //     isRefetching = true;
    //     resetTableScrollAndPageData();
    //     fetchCredits(0);
    // };

    /**
     * Function for populating the loading text of table.
     */
    const populateTableLoadingText = () => {
        const loadingText = `Fetching ${
            tableCurrentPage === 0 || isRefetching ? 'list of' : 'more'
        } credits`;

        isRefetching = false;

        return loadingText;
    };

    /**
     * Function for populating the modal title when saving the credit data.
     */
    const populateCreditBatchSaveRequestModalTitle = () => {
        const { keysToUse } = getSelectedCreditsValues();

        return `Saving credit${keysToUse.length > 1 ? 's' : ''} data`;
    };

    /**
     * Function for populating the modal body when saving the credit data.
     */
    const populateCreditBatchSaveRequestModalDisplayMessage = () => {
        const { keysToUse } = getSelectedCreditsValues();

        return `Please wait while saving the credit${
            keysToUse.length > 1 ? "s'" : "'s"
        } classification status. . .`;
    };

    const populateDeleteCreditsModalTitle = () => {
        const { keysToUse } = getSelectedCreditsValues();

        return `Deleting credit${keysToUse.length > 1 ? 's' : ''}`;
    };

    const populateDeleteCreditsModalDisplayMessage = () => {
        const { keysToUse } = getSelectedCreditsValues();

        return `Please wait while deleting the credit${keysToUse.length > 1 ? 's' : ''} . . .`;
    };

    /**
     * Function called when panel is closed.
     * @param panelType - type of panel to be closed. Must be the same with showConditions state props
     */
    const closePanel = (panelType: string) => {
        return (refreshList?: boolean) => {
            updateShowConditionsObject({
                [panelType]: false,
            });

            if (refreshList) {
                refetchListAndResetScroll();
            }
        };
    };

    /**
     * Function for getting the payload for adding comment.
     */
    // const getAddCommentFilterPayload = () => {
    //     const {
    //         allExcept,
    //         keysToUse,
    //         filterObject,
    //     } = getSelectedCreditsValues();

    //     return {
    //         filter: filterObject,
    //         creditIds: keysToUse,
    //         excludeCredits: allExcept,
    //     };
    // };

    /**
     * Function that checks if the Name of the page view to be saved already exists.
     * @param name - name of page view
     */
    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };

    /**
     * Function that fetches report variables
     */
    const getDownloadReportVariables = () => {
        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );
        const { filters, sortBy, sortAscending, creditState } =
            filterAndPageProps;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'credit',
            true,
        );

        const variables = {
            ...cleanFilters,
            CreditState: creditState,
            SortField: sortBy,
            Ascending: sortAscending,
        };

        return variables;
    };

    /**
    * Function called when click download specialised reports is closed.
    */
    const closeSpecialisedReportsPanel = () => {
        updateShowConditionsObject({
            specialisedReportsPanel: false
        });
    };

    /**
    * Function that fetches the customer first letter values
    */
    const getCustomerFirstLatterValuesOptions = async (
        options: DynamicObject[],
        valuesList: DynamicObject[]
    ) => {
        forEach(
            valuesList,
            (value, index) => {
                options.push({
                    label: value,
                    value: value
                });
            }
        );
        return options;
    };
    const getCustomerFirstLetterValuesList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');
        let options: DynamicObject[] = [];

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getCustomerFirstLetterValuesRequestAction(
                    selectedCompanyId,
                    async (response: any) => {
                        if (response.IsSuccess) {
                            options = await getCustomerFirstLatterValuesOptions(options, response.Values);
                            setCustomerValuesList(options)
                        }
                    }
                )
            );
        }
    };
    useEffect(getCustomerFirstLetterValuesList, [selectedUserCompany]);

    return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container">
                    <Col span={24}>
                        <Title level={3}>{getTranslatedText('Credits')}</Title>
                    </Col>
                </Row>
                <div className="spacer-15" />
                {/* Filter Bar */}
                <QueueAnim type="top" leaveReverse duration={300}>
                    {showConditions.filterBar && (
                        <div key="filter-bar-container">
                            <Suspense fallback={null}>
                                <FilterBar
                                    pageName={pageName}
                                    loading={creditsState.loading}
                                    applyFilters={applyFilters}
                                    filters={getCreditsFilterBarFilters(
                                        customerValuesOption,
                                        capitalize(customerLabel) + ' A-Z',
                                        isCalendarView
                                    )}
                                    filterValues={creditsState.filters}
                                    colDivision={4}
                                    closeAllPopovers={
                                        creditDetailsDrawer.visible
                                    }
                                    closeFilterBar={closeFilterBar}
                                    appliedView={creditsTableFilter}
                                    doesViewNameExist={doesViewNameExist}
                                    customFieldsFilters={IsSearchCustomFieldsByCheckBox ? customFieldValuesList : customFieldsFilterList}
                                    tableSortState={{
                                        sortBy: creditsState.sortBy,
                                        creditState: creditsState.creditState,
                                        sortAscending:
                                            creditsState.sortAscending,
                                    }}
                                />
                            </Suspense>
                        </div>
                    )}
                    <SpecialisedReportsDrawer
                        visible={showConditions.specialisedReportsPanel}
                        onClose={closeSpecialisedReportsPanel}
                        downloadToExcelCallBack={downloadToExcelHandler}
                        reportOptions={{
                            options: SpecialisedReports,
                            loading: false
                        }}
                    />
                </QueueAnim>
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        pageName={pageName}
                        loading={creditsState.loading}
                        filterBarOpen={showConditions.filterBar}
                        actionItems={[
                            {
                                actionKey: 'credit-filter',
                                actionType: 'select-with-button',
                                selectValue: creditsTableFilter,
                                selectDropdownRender:
                                    populateCreditFilterSelectDropdownRender,
                                onSelectChange: changeCreditsTableFilter,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'sync']}
                                            className="mr-8"
                                        />
                                        <span>{getTranslatedText('Refresh')}</span>
                                    </>
                                ),
                                buttonDisabled: creditsState.loading,
                                onButtonClick: handleCreditFilterRefresh,
                            },
                            {
                                actionKey: 'credit-select-all',
                                actionType: 'protected-button',
                                buttonDisabled: downloadDisabled,
                                onButtonClick: selectAllRows,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'check-double']}
                                        />
                                        <span>
                                            {getTranslatedText(populateSelectDeselectAllLabel())}
                                        </span>
                                    </>
                                ),
                            },
                            // {
                            //     actionKey: 'credit-add-comment',
                            //     actionType: 'protected-drawer-button',
                            //     popoverVisible: showConditions.addCommentDrawer,
                            //     drawerCloseable: false,
                            //     popoverOnVisibleChange: popoverOnVisibleChange(
                            //         'addCommentDrawer',
                            //         !isEmpty(tableRowSelection.selectedRowKeys)
                            //     ),
                            //     popoverTitle: 'Add a comment',
                            //     popoverContent: (
                            //         <AddCommentPanel
                            //             visible={
                            //                 showConditions.addCommentDrawer
                            //             }
                            //             closePanel={closePanel(
                            //                 'addCommentDrawer'
                            //             )}
                            //             filterPayload={getAddCommentFilterPayload()}
                            //             dispatchAction={
                            //                 creditAddCommentRequestAction
                            //             }
                            //         />
                            //     ),
                            //     buttonDisabled: isEmpty(
                            //         tableRowSelection.selectedRowKeys
                            //     ),
                            //     buttonContent: (
                            //         <>
                            //             <FontAwesome
                            //                 icon={['fas', 'comment']}
                            //             />
                            //             <span>Add comment</span>
                            //         </>
                            //     ),
                            //     drawerWidth: 500,
                            // },
                            // {
                            //     actionKey: 'credit-allocate-to-invoice',
                            //     actionType: 'protected-drawer-button',
                            //     popoverVisible:
                            //         showConditions.allocateToInvoiceDrawer,
                            //     drawerCloseable: false,
                            //     popoverOnVisibleChange: popoverOnVisibleChange(
                            //         'allocateToInvoiceDrawer',
                            //         !isEmpty(tableRowSelection.selectedRowKeys)
                            //     ),
                            //     popoverTitle: 'Allocate to invoice',
                            //     popoverContent: (
                            //         <div>
                            //             <CreditAllocateToInvoicePanel
                            //                 visible={
                            //                     showConditions.allocateToInvoiceDrawer
                            //                 }
                            //                 closePanel={closePanel(
                            //                     'allocateToInvoiceDrawer'
                            //                 )}
                            //                 // filterPayload={getAllocateToInvoiceFilterPayload()}
                            //                 // dispatchAction={
                            //                 //     creditAllocateToInvoiceRequestAction
                            //                 // }
                            //             />
                            //         </div>
                            //     ),
                            //     buttonDisabled: isEmpty(
                            //         tableRowSelection.selectedRowKeys
                            //     ),
                            //     buttonContent: (
                            //         <>
                            //             <div className="fa-layers fa-fw fa">
                            //                 <FontAwesome
                            //                     icon={['fa', 'file']}
                            //                 />
                            //                 <FontAwesome
                            //                     icon={['fa', 'plus']}
                            //                     transform="shrink-8 right-1 down-2"
                            //                     style={{
                            //                         color: 'white',
                            //                     }}
                            //                 />
                            //             </div>
                            //             <span>Allocate to invoice</span>
                            //         </>
                            //     ),
                            //     drawerWidth: 500,
                            // },
                            {
                                actionKey: 'credit-reconcile-report',
                                actionType: 'protected-drawer-button',
                                popoverVisible:
                                    showConditions.reconcileReportDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'reconcileReportDrawer'
                                ),
                                popoverTitle: getTranslatedText('Reconcile report'),
                                popoverContent: (
                                    <div>
                                        <CreditReconcileReportPanel
                                            visible={
                                                showConditions.reconcileReportDrawer
                                            }
                                            closePanel={closePanel(
                                                'reconcileReportDrawer'
                                            )}
                                            dispatchAction={
                                                getCreditsReconciliationReportRequestAction
                                            }
                                        />
                                    </div>
                                ),
                                buttonDisabled: creditsState.loading,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'file-download']}
                                        />
                                        <span>{getTranslatedText('Reconcile report')}</span>
                                    </>
                                ),
                                drawerWidth: 500,
                            },
                            {
                                actionKey: 'create-credit-manually',
                                actionType: 'protected-drawer-button',
                                allowedRoles: rolePermissions.CREATE_CREDIT_MANUALLY,
                                popoverVisible:
                                    showConditions.createCreditManuallyDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'createCreditManuallyDrawer'
                                ),
                                popoverTitle: getTranslatedText('Create credits'),
                                popoverContent: (
                                    <div>
                                        <CreateCreditManuallyPanel
                                            visible={
                                                showConditions.createCreditManuallyDrawer
                                            }
                                            closePanel={closePanel(
                                                'createCreditManuallyDrawer'
                                            )}
                                            dispatchAction={
                                                createCreditManuallyRequestAction
                                            }
                                        />
                                    </div>
                                ),
                                buttonDisabled: creditsState.loading,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'plus-circle']}
                                        />
                                        <span>{getTranslatedText('Create credits')}</span>
                                    </>
                                ),
                                drawerWidth: 700,
                            },
                            {
                                actionKey: 'edit-credit-manually',
                                actionType: 'protected-drawer-button',
                                allowedRoles: rolePermissions.EDIT_CREDIT_MANUALLY,
                                popoverVisible:
                                    showConditions.editCreditManuallyDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'editCreditManuallyDrawer'
                                ),
                                popoverTitle: getTranslatedText('Edit credit'),
                                popoverContent: (
                                    <div>
                                        <CreateCreditItemManuallyDrawerContentWrapper 
                                            visible={showConditions.editCreditManuallyDrawer}
                                            isEditMode={true}
                                            isEditViaManamentPage={true}
                                            closePanel={closePanel('editCreditManuallyDrawer')}
                                            editCredit={getSelectedCredit()}
                                        />
                                    </div>
                                ),
                                buttonDisabled: !editAllowed,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'edit']}
                                        />
                                        <span>{getTranslatedText('Edit credit')}</span>
                                    </>
                                ),
                                drawerWidth: 700,
                            },
                            {
                                actionKey: 'delete-credit-manually',
                                actionType: 'protected-button',
                                allowedRoles: rolePermissions.DELETE_CREDIT_MANUALLY,
                                buttonDisabled: !deleteAllowed,
                                hideElement: !SupportCashAllocation,
                                onButtonClick: onDeleteClick,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'trash']}
                                        />
                                        <span>{getTranslatedText('Delete')}</span>
                                    </>
                                )
                            }
                        ]}
                        actionEllipsis={{
                            popoverVisible: showConditions.filterEllipsis,
                            popoverOnVisibleChange:
                                popoverOnVisibleChange('filterEllipsis'),
                            popoverTitle: populateFilterEllipsisPopoverTitle(),
                            popoverContent:
                                populateFilterEllipsisPopoverContent(),
                            buttonContent: (
                                <FontAwesome icon={['fas', 'ellipsis-h']} />
                            ),
                        }}
                    />
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row key="table-container">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={creditsState.data}
                            fetchMore={handleFetch}
                            scroll={computeTableScroll(
                                window.innerHeight - 185,
                                tablePageSize,
                                CREDITS_PAGE.rowHeight
                            )}
                            resetTableScroll={resetTableScroll}
                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                            rerenderTrigger={tableRowSelection.selectedRowKeys}
                            onRowClick={onRowClick}
                            onCheckboxClick={onCheckboxClick}
                            loading={creditsState.loading}
                            loadingText={populateTableLoadingText()}
                            emptyText={
                                !isEmpty(creditsState.errorMessages)
                                    ? get(creditsState, 'errorMessages.0')
                                    : 'No credits found'
                            }
                            hasNextPage={creditsState.pageData.hasNextPage}
                            itemComponent={CreditItemComponent}
                            itemHeight={CREDITS_PAGE.rowHeight}
                            selectedId={selectedId}
                        />
                    </Col>
                    <Suspense fallback={null}>
                        <CreditItemDetailsDrawerComponent
                            visible={creditDetailsDrawer.visible}
                            activeTab={creditDetailsDrawer.activeTab}
                            closeDrawer={closeCreditDetailsDrawer}
                            refetchList={refetchListAndResetScroll}
                            getCreditDataRequestAction={getCreditDataRequestAction}
                        />
                    </Suspense>
                    {creditsState.saveCreditDataLoading && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={getTranslatedText(populateCreditBatchSaveRequestModalTitle())}
                                modalVisible={
                                    creditsState.saveCreditDataLoading
                                }
                                displayMessage={getTranslatedText(populateCreditBatchSaveRequestModalDisplayMessage())}
                            />
                        </Suspense>
                    )}
                    {showConditions.downloadToExcel && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={getTranslatedText(populateDownloadToExcelModalTitle())}
                                modalVisible={showConditions.downloadToExcel}
                                displayMessage={getTranslatedText(populateDownloadToExcelModalDisplayMessage())}
                            />
                        </Suspense>
                    )}
                    {deleteLoading && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={getTranslatedText(populateDeleteCreditsModalTitle())}
                                modalVisible={deleteLoading}
                                displayMessage={getTranslatedText(populateDeleteCreditsModalDisplayMessage())}
                            />
                        </Suspense>
                    )}
                    <CreateScheduledReportDrawer
                        visible={showConditions.createScheduledReport}
                        onClose={onCreateScheduledReportEnd}
                        widgetQuery={createScheduleReportPayload}
                    />
                </Row>
            </QueueAnim>
        </Col>
    );
};

export default withPageViewHandler(
    withAccountingSystemHandler(CreditsManagementPage)
);
