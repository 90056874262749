import { remittanceAdvicesSortByOptions } from "../../constants/remittanceAdvicesSortAndFilter";
import { AddCommentCommonFilterPayload, DynamicObject } from "../../utils/commonInterfaces";
import { Attachment, ConversationLine, PageData, ConversationState } from "../common/types";
import { Credit, GetCreditsRequestPayload, GetRebatesRequestPayload } from "../credits/types";
import { Customer, GetCustomersRequestPayload } from "../customers/types";
import { GetInvoicesRequestPayload, Invoice } from "../invoices/types";
import { Payment } from "../payments/types";

export enum RemittanceAdvicesActionTypes {
    GET_REMITTANCE_ADVICES_REQUEST = '@@remittanceadvices/GET_REMITTANCE_ADVICES_REQUEST',
    GET_REMITTANCE_ADVICES_SUCCESS = '@@remittanceadvices/GET_REMITTANCE_ADVICES_SUCCESS',

    GET_REMITTANCE_ADVICE_DETAILS_REQUEST = '@@remittanceadvices/GET_REMITTANCE_ADVICE_DETAILS_REQUEST',
    GET_REMITTANCE_ADVICE_DETAILS_SUCCESS = '@@remittanceadvices/GET_REMITTANCE_ADVICE_DETAILS_SUCCESS',
    GET_REMITTANCE_ADVICE_DETAILS_ERROR = '@@remittanceadvices/GET_REMITTANCE_ADVICE_DETAILS_ERROR',
    SET_REMITTANCE_ADVICE_SELECTED_ID_REQUEST = '@@remittanceadvices/SET_REMITTANCE_ADVICE_SELECTED_ID_REQUEST',
    SET_REMITTANCE_ADVICE_SELECTED_ID_SUCCESS = '@@remittanceadvices/SET_REMITTANCE_ADVICE_SELECTED_ID_SUCCESS',
    CLEAR_REMITTANCE_ADVICE_SELECTED_SUCCESS = '@@remittanceadvices/CLEAR_REMITTANCE_ADVICE_SELECTED_DATA_SUCCESS',
    GET_REMITTANCE_ADVICE_SUMMARY_REQUEST = '@@remittanceadvices/GET_REMITTANCE_ADVICE_SUMMARY_REQUEST',
    GET_REMITTANCE_ADVICE_SUMMARY_SUCCESS = '@@remittanceadvices/GET_REMITTANCE_ADVICE_SUMMARY_SUCCESS',
    GET_REMITTANCE_ADVICE_SUMMARY_ERROR = '@@remittanceadvices/GET_REMITTANCE_ADVICE_SUMMARY_ERROR',

    CONFIRM_REMITTANCE_ADVICE_REQUEST = '@@remittanceadvices/CONFIRM_REMITTANCE_ADVICE_REQUEST',

    REMITTANCE_ADVICE_ADD_COMMENT_REQUEST = '@@remittanceadvices/REMITTANCE_ADVICE_ADD_COMMENT_REQUEST',

    UPDATE_REMITTANCE_ADVICE_SORT_BY_AND_STATE = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_SORT_BY_AND_STATE',
    UPDATE_REMITTANCE_ADVICE_FILTERS = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_FILTERS',
    UPDATE_REMITTANCE_ADVICE_TABLE_FILTER_STATE = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_TABLE_FILTER_STATE',
    CLEAR_REMITTANCE_ADVICE_DATA_SUCCESS = '@@remittanceadvices/CLEAR_REMITTANCE_ADVICE_DATA_SUCCESS',

    GET_REMITTANCE_ADVICE_CUSTOMERS_REQUEST = '@@remittanceadvices/GET_REMITTANCE_ADVICE_CUSTOMERS_REQUEST',
    GET_REMITTANCE_ADVICE_CUSTOMERS_SUCCESS = '@@remittanceadvices/GET_REMITTANCE_ADVICE_CUSTOMERS_SUCCESS',
    GET_REMITTANCE_ADVICE_CUSTOMERS_ERROR = '@@remittanceadvices/GET_REMITTANCE_ADVICE_CUSTOMERS_ERROR',
    UPDATE_REMITTANCE_ADVICE_CUSTOMERS_FILTERS = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_CUSTOMERS_FILTERS',
    UPDATE_REMITTANCE_ADVICE_CUSTOMERS_SORT_BY_AND_STATE = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_CUSTOMERS_SORT_BY_AND_STATE',
    UPDATE_REMITTANCE_ADVICE_CUSTOMERS_TABLE_FILTER_STATE = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_CUSTOMERS_TABLE_FILTER_STATE',
    GET_RA_CUSTOM_FIELDS_FOR_COMPANY_REQUEST = '@@remittanceadvices/GET_RA_CUSTOM_FIELDS_FOR_COMPANY_REQUEST',
    GET_REMITTANCE_ADVICE_INVOICES_REQUEST = '@@remittanceadvices/GET_REMITTANCE_ADVICE_INVOICES_REQUEST',
    GET_REMITTANCE_ADVICE_INVOICES_SUCCESS = '@@remittanceadvices/GET_REMITTANCE_ADVICE_INVOICES_SUCCESS',
    
    GET_CONFRIM_REMITTANCE_ADVICE_INVOICES_SUCCESS = '@@Rremittanceadvices/GET_CONFRIM_REMITTANCE_ADVICE_INVOICES_SUCCESS',
    GET_CONFRIM_REMITTANCE_ADVICE_CREDITS_SUCCESS = '@@remittanceadvices/GET_CONFRIM_REMITTANCE_ADVICE_CREDITS_SUCCESS',
    GET_CONFRIM_REMITTANCE_ADVICE_REBATES_SUCCESS ='@@remittanceadvices/GET_CONFRIM_REMITTANCE_ADVICE_REBATES_SUCCESS',

    GET_REMITTANCE_ADVICE_INVOICES_ERROR = '@@remittanceadvices/GET_REMITTANCE_ADVICE_INVOICES_ERROR',
    UPDATE_REMITTANCE_ADVICE_INVOICES_FILTERS = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_INVOICES_FILTERS',
    UPDATE_REMITTANCE_ADVICE_INVOICES_SORT_BY_AND_STATE = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_INVOICES_SORT_BY_AND_STATE',
    UPDATE_REMITTANCE_ADVICE_INVOICES_TABLE_FILTER_STATE = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_INVOICES_TABLE_FILTER_STATE',

    CONFIRM_REMITTANCE_ADVICE_DISREGARD_TASKS_REQUEST = '@@remittanceadvices/CONFIRM_REMITTANCE_ADVICE_DISREGARD_TASKS_REQUEST',
    ORGANISATION_CONFIRM_REMITTANCE_ADVICE_DISREGARD_TASKS_REQUEST = '@@remittanceadvices/ORGANISATION_CONFIRM_REMITTANCE_ADVICE_DISREGARD_TASKS_REQUEST',
    GET_REMITTANCE_ADVICE_CREDITS_REQUEST ='@@remittanceadvices/GET_REMITTANCE_ADVICE_CREDITS_REQUEST',
    GET_REMITTANCE_ADVICE_CREDITS_SUCCESS = '@@remittanceadvices/GET_REMITTANCE_ADVICE_CREDITS_SUCCESS',
    GET_REMITTANCE_ADVICE_CREDITS_ERROR = '@@remittanceadvices/GET_REMITTANCE_ADVICE_CREDITS_ERROR',
    UPDATE_REMITTANCE_ADVICE_CREDITS_FILTERS = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_CREDITS_FILTERS',
    UPDATE_REMITTANCE_ADVICE_CREDITS_SORT_BY_AND_STATE = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_CREDITS_SORT_BY_AND_STATE',
    UPDATE_REMITTANCE_ADVICE_CREDITS_TABLE_FILTER_STATE = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_CREDITS_TABLE_FILTER_STATE',

    GET_REMITTANCE_ADVICE_REBATES_REQUEST ='@@remittanceadvices/GET_REMITTANCE_ADVICE_REBATES_REQUEST',
    GET_REMITTANCE_ADVICE_REBATES_SUCCESS = '@@remittanceadvices/GET_REMITTANCE_ADVICE_REBATES_SUCCESS',
    GET_REMITTANCE_ADVICE_REBATES_ERROR = '@@remittanceadvices/GET_REMITTANCE_ADVICE_REBATES_ERROR',
    UPDATE_REMITTANCE_ADVICE_REBATES_FILTERS = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_REBATES_FILTERS',
    UPDATE_REMITTANCE_ADVICE_REBATES_SORT_BY_AND_STATE = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_REBATES_SORT_BY_AND_STATE',
    UPDATE_REMITTANCE_ADVICE_REBATES_TABLE_FILTER_STATE = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_REBATES_TABLE_FILTER_STATE',

    GET_REMITTANCE_ADVICE_CONVERSATION_REQUEST = '@@remittanceAdvices/GET_REMITTANCE_ADVICE_CONVERSATION_REQUEST',
    GET_REMITTANCE_ADVICE_CONVERSATION_SUCCESS = '@@remittanceAdvices/GET_REMITTANCE_ADVICE_CONVERSATION_SUCCESS',
    GET_REMITTANCE_ADVICE_CONVERSATION_ERROR = '@@remittanceAdvices/GET_REMITTANCE_ADVICE_CONVERSATION_ERROR',
    UPDATE_REMITTANCE_ADVICE_CONVERSATION_FILTERS = '@@remittanceAdvices/UPDATE_REMITTANCE_ADVICE_CONVERSATION_FILTERS',
    UPDATE_REMITTANCE_ADVICE_CONVERSATION_TABLE_FILTER = '@@remittanceAdvices/UPDATE_REMITTANCE_ADVICE_CONVERSATION_TABLE_FILTER',
    UPDATE_REMITTANCE_ADVICE_CONVERSATION_SORT_BY = '@@remittanceAdvices/UPDATE_REMITTANCE_ADVICE_CONVERSATION_SORT_BY',

    GET_REMITTANCE_ADVICE_CHANGES_REQUEST = '@@remittanceAdvices/GET_REMITTANCE_ADVICE_CHANGES_REQUEST',
    GET_REMITTANCE_ADVICE_CHANGES_SUCCESS = '@@remittanceAdvices/GET_REMITTANCE_ADVICE_CHANGES_SUCCESS',
    GET_REMITTANCE_ADVICE_CHANGES_ERROR = '@@remittanceAdvices/GET_REMITTANCE_ADVICE_CHANGES_ERROR',
    UPDATE_REMITTANCE_ADVICE_CHANGES_FILTERS = '@@remittanceAdvices/UPDATE_REMITTANCE_ADVICE_CHANGES_FILTERS',
    UPDATE_REMITTANCE_ADVICE_CHANGES_TABLE_FILTER = '@@remittanceAdvices/UPDATE_REMITTANCE_ADVICE_CHANGES_TABLE_FILTER',
    UPDATE_REMITTANCE_ADVICE_CHANGES_SORT_BY = '@@remittanceAdvices/UPDATE_REMITTANCE_ADVICE_CHANGES_SORT_BY',
    
    GET_REMITTANCE_ADVICE_PAYMENTS_REQUEST = '@remittanceadvices/GET_REMITTANCE_ADVICE_PAYMENTS_REQUEST',
    GET_REMITTANCE_ADVICE_PAYMENTS_SUCCESS = '@@remittanceadvices/GET_REMITTANCE_ADVICE_PAYMENTS_SUCCESS',
    GET_REMITTANCE_ADVICE_PAYMENTS_ERROR = '@@remittanceadvices/GET_REMITTANCE_ADVICE_PAYMENTS_ERROR',
    UPDATE_REMITTANCE_ADVICE_PAYMENTS_FILTERS = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_PAYMENTS_FILTERS',
    UPDATE_REMITTANCE_ADVICE_PAYMENTS_SORT_BY = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_PAYMENTS_SORT_BY',
    UPDATE_REMITTANCE_ADVICE_PAYMENTS_TABLE_FILTER = '@@remittanceadvices/UPDATE_REMITTANCE_ADVICE_PAYMENTS_TABLE_FILTER',

    REVIEW_REMITTANCE_ADVICE_REQUEST = '@@remittanceadvices/REVIEW_REMITTANCE_ADVICE_REQUEST',
    REVIEW_ORGANISATION_REMITTANCE_ADVICE_REQUEST = '@@remittanceadvices/REVIEW_ORGANISATION_REMITTANCE_ADVICE_REQUEST',
    REVIEW_REMITTANCE_ADVICE_SUCCESS = '@@remittanceadvices/REVIEW_REMITTANCE_ADVICE_SUCCESS',

    GET_RA_PROCESSING_CONFIGURATION_REQUEST = '@@remittanceadvices/GET_RA_PROCESSING_CONFIGURATION_REQUEST',
    GET_RA_PROCESSING_CONFIGURATION_SUCCESS = '@@remittanceadvices/GET_RA_PROCESSING_CONFIGURATION_SUCCESS',
    GET_RA_PROCESSING_CONFIGURATION_ERROR = '@@remittanceAdvices/GET_RA_PROCESSING_CONFIGURATION_ERROR',
    SAVE_RA_PROCESSING_CONFIGURATION_REQUEST = '@@remittanceAdvices/SAVE_RA_PROCESSING_CONFIGURATION_REQUEST',
    SAVE_RA_PROCESSING_CONFIGURATION_SUCCESS = '@@remittanceAdvices/SAVE_RA_PROCESSING_CONFIGURATION_RESPONSE',
    SAVE_RA_PROCESSING_CONFIGURATION_ERROR = '@@remittanceAdvices/SAVE_RA_PROCESSING_CONFIGURATION_RESPONSE',

    GET_RA_REBATE_MAPPINGS_REQUEST = '@@remittanceadvices/GET_RA_REBATE_MAPPINGS_REQUEST',
    GET_RA_REBATE_MAPPINGS_SUCCESS = '@@remittanceadvices/GET_RA_REBATE_MAPPINGS_SUCCESS',
    GET_RA_REBATE_MAPPINGS_ERROR = '@@remittanceAdvices/GET_RA_REBATE_MAPPINGS_ERROR',

    SAVE_RA_REBATE_MAPPING_CONFIGURATION_REQUEST = '@@remittanceadvices/SAVE_RA_REBATE_MAPPING_CONFIGURATION_REQUEST',
    SAVE_RA_REBATE_MAPPING_CONFIGURATION_RESPONSE = '@@remittanceadvices/SAVE_RA_REBATE_MAPPING_CONFIGURATION_RESPONSE',
    SAVE_REMITTANCE_ADVICE_REQUEST = '@@remittanceadvice/SAVE_REMITTANCE_ADVICE_REQUEST',

    SAVE_INVOICE_REMITTANCE_ADVICE_REQUEST = '@@remittanceadvice/SAVE_INVOICE_REMITTANCE_ADVICE_REQUEST',
    SAVE_INVOICE_REMITTANCE_ADVICE_SUCCESS ='@@remittanceadvice/SAVE_INVOICE_REMITTANCE_ADVICE_SUCCESS',

    SAVE_CREDIT_REMITTANCE_ADVICE_REQUEST = '@@remittanceadvice/SAVE_CREDIT_REMITTANCE_ADVICE_REQUEST',
    SAVE_CREDIT_REMITTANCE_ADVICE_SUCCESS ='@@remittanceadvice/SAVE_CREDIT_REMITTANCE_ADVICE_SUCCESS',

    SAVE_REBATE_REMITTANCE_ADVICE_REQUEST = '@@remittanceadvice/SAVE_REBATE_REMITTANCE_ADVICE_REQUEST',
    SAVE_REBATE_REMITTANCE_ADVICE_SUCCESS ='@@remittanceadvice/SAVE_REBATE_REMITTANCE_ADVICE_SUCCESS'

}

export interface RemittanceAdvicesState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly activeData: {
        loading: boolean;
        record?: RemittanceAdvice;
        conversation: ConversationState;
        remittanceAdviceChanges: RemittanceAdviceChangesState;
        selectedId: string | null;
        errorMessages: string[];
        invoices: {
            readonly loading: boolean;
            readonly errorMessages: string[];
            readonly data: Invoice[];
            readonly pageData: PageData;
            readonly filters: GetInvoicesRequestPayload['filters'];
            readonly sortBy: GetInvoicesRequestPayload['sortBy'];
            readonly sortAscending: boolean;
            readonly invoiceState: string;
        }
        credits: {
            readonly loading: boolean;
            readonly errorMessages: string[];
            readonly data: Credit[];
            readonly pageData: PageData;
            readonly filters: GetCreditsRequestPayload['filters'];
            readonly sortBy: GetCreditsRequestPayload['sortBy'];
            readonly sortAscending: boolean;
            readonly creditState: string;
        };
        rebates: {
            readonly loading: boolean;
            readonly errorMessages: string[];
            readonly data: Credit[];
            readonly pageData: PageData;
            readonly filters: GetRebatesRequestPayload['filters'];
            readonly sortBy: GetRebatesRequestPayload['sortBy'];
            readonly sortAscending: boolean;
            readonly creditState: string;
        };
        customers: {
            readonly loading: boolean;
            readonly errorMessages: string[];
            readonly data: Customer[];
            readonly pageData: PageData;
            readonly filters: GetMultipleCustomersRequestPayload['filters'];
            readonly sortBy: GetCustomersRequestPayload['sortBy'];
            readonly sortAscending: boolean;
            readonly customerState: string;
        };
        payments: Payment[];
        Allocation: {
            AllocationErrors: DynamicObject[];
            AllocationItems: DynamicObject[];
        };
    };
    readonly filters: GetRemittanceAdvicesRequestPayload['filters'];
    readonly tableFilter: string | undefined;
    readonly SortField: GetRemittanceAdvicesRequestPayload['SortField'];
    readonly sortAscending: boolean;
    readonly ActiveState: string;
    readonly pageData: PageData;
    readonly data: RemittanceAdvice[];
    readonly raProcessingConfiguration: RAProcessingConfigurationState
}

export interface GetMultipleCustomersRequestPayload {
    filters: {
        Customer: string;
        CustomerCode: string;
    };
}

export interface RemittanceAdvice extends DynamicObject {
    readonly Id: string;
    readonly CompanyId: string;
    readonly Status: string;
    readonly ReceivedDate: string;
    readonly LocalReceivedDate: string;
    readonly PaidDate: string;
    readonly LocalPaidDate: string;
    readonly Amount: number;
    readonly MultipleCustomer: boolean;
    readonly Customer: Customer;
    readonly Uri: string;
    readonly Attachments: Attachment[];
    readonly RemittanceAdviceInvoices: RemittanceAdviceInvoice[];
    readonly RemittanceAdviceCredits: RemittanceAdviceCredits[];
    readonly RemittanceAdviceRebates: RemittanceAdviceRebates[];
}

export interface RemittanceAdviceInvoice {
    readonly Invoice: Invoice;
    readonly CustomerId: string;
    readonly Number: string;
    readonly AmountOwing: number;
    readonly Amount: number;
    readonly State: string;
}

export interface RemittanceAdviceCredits {
    readonly Credit: Credit;
    readonly CustomerId: string;
    readonly Number: string;
    readonly AmountOwing: number;
    readonly Amount: number;
    readonly InvoiceId: string;
    readonly State: string;
    readonly Invoice: Invoice;
}

export interface RemittanceAdviceRebates {
    readonly Rebate: Credit;
    readonly RebateId: string;
    readonly CustomerId: string;
    readonly Number: string;
    readonly AmountOwing: number;
    readonly Amount: number;
    readonly AmountToAllocate: number;
    readonly Percentage: number;
    readonly State: string;
    // readonly CalculatePercentage: boolean; 
}

export interface GetRemittanceAdvicesRequestPayload {
    CompanyId?: string,
    CustomerId?: string,
    RemittanceAdviceState?: number, 
    PaidDateMin?: string,
    PaidDateMax?: string,
    PaidAmountMin?: string,
    PaidAmountMax?: string,
    AmountType?: string,
    Skip?: number,
    callback?: (response: any) => void;
    Ascending?: boolean,
    RemittanceAdviceId?: string,
    MarkAsOverpayment?: boolean,

    filters: {};
    SortField: typeof remittanceAdvicesSortByOptions[number]['value'];
    sortAscending: boolean;
    ActiveState?: string;
    pageSize: number;
    currentPage: number;
    isUsingCloudImportType: boolean;
}

export interface RemittanceAdvices extends DynamicObject {
            readonly Id: string,
            readonly CompanyId: string,
            readonly Customer: Customer
            readonly Status: number
            readonly ReceivedDate: string
            readonly LocalReceivedDate: string
            readonly PaidDate: string
            readonly LocalPaidDate: string
            readonly Amount: number
            readonly MultipleCustomer: boolean
            readonly ConversationLine: ConversationLine
            readonly Uri: string
            readonly Attachments: Attachment
            readonly RemittanceAdviceInvoices: RemittanceAdviceInvoice
            readonly RemittanceAdviceCredits: RemittanceAdviceCredits
            readonly RemittanceAdviceRebates: RemittanceAdviceRebates
            readonly AllocatedAmount: number
            readonly WithOverpayments: boolean
}

export interface RemittanceAdviceAddCommentRequestPayload
    extends AddCommentCommonFilterPayload {
    RemittanceAdvicesIds: string[] | number[];
    excludeRemittanceAdvices: boolean;
}

export interface RemittanceAdviceChangesState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: RemittanceAdvice[];
    readonly pageData: PageData;
    readonly filters: GetRemittanceAdviceChangesRequestPayload['filters'];
    readonly remittanceAdviceChangesTableFilter: string;
    readonly sortBy: GetRemittanceAdviceChangesRequestPayload['sortBy'];
    readonly sortAscending: boolean;
}

export interface GetRemittanceAdviceChangesRequestPayload {
    filters: {};
    sortBy: string;
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    remittanceAdviceChangesTableFilter: string;
}

export interface RemittanceAdviceChanges { }

export interface RAProcessingConfigurationState {
    readonly loading: boolean;
    readonly saveLoading: boolean;
    readonly errorMessages: string[];
    readonly data: RAProcessingConfiguration | undefined;
}

export interface RAProcessingConfiguration {
    readonly CompanyId: string,
	readonly TypeHeaders: string,
	readonly InvoiceTypeValues: string,
	readonly CreditTypeValues: string,
	readonly RebateTypeValues: string,
	readonly NumberHeaders: string,
	readonly InvoiceNumberValues: string,
	readonly CreditNumberValues: string,
	readonly RebateNumberValues: string,
	readonly InvoiceNumberRemoveableValues: string,
	readonly CreditNumberRemoveableValues: string,
	readonly RebateNumberRemoveableValues: string,
	readonly DescriptionHeaders: string,
	readonly InvoiceDescriptionValues: string,
	readonly CreditDescriptionValues: string,
	readonly RebateDescriptionValues: string,
	readonly AmountHeaders: string,
	readonly CustomerCodeHeaders: string,
	readonly CreatedDateHeaders: string,
	readonly DueDateHeaders: string,
	readonly PaymentDateSummaryFields: string,
	readonly TotalPaymentSummaryFields: string,
	readonly AdjustmentAmount: number,
	readonly AdjustmentIdentifierPrepend: string,
	readonly AdjustmentIdentifierAppend: string,
	readonly AllowHighConfidence: false
}