import {
    paymentPlansSortByOptions,
    paymentScheduleSortByOptions,
} from '../../constants/paymentPlansSortAndFilters';
import {
    AddCommentCommonFilterPayload,
    DynamicObject,
} from '../../utils/commonInterfaces';
import { ConversationLine, PageData, CustomField } from '../common/types';
import { GetInvoicesRequestPayload, Invoice } from '../invoices/types';

export enum PaymentPlansActionTypes {
    GET_PAYMENT_PLANS_REQUEST = '@@paymentPlans/GET_PAYMENT_PLANS_REQUEST',
    GET_PAYMENT_PLANS_SUCCESS = '@@paymentPlans/GET_PAYMENT_PLANS_SUCCESS',
    GET_PAYMENT_PLANS_ERROR = '@@paymentPlans/GET_PAYMENT_PLANS_ERROR',
    UPDATE_PAYMENT_PLANS_FILTERS = '@@paymentPlans/UPDATE_PAYMENT_PLANS_FILTERS',
    UPDATE_PAYMENT_PLANS_SORT_BY_AND_STATE = '@@paymentPlans/UPDATE_PAYMENT_PLANS_SORT_BY_AND_STATE',
    UPDATE_PAYMENT_PLANS_TABLE_FILTER_STATE = '@@paymentPlans/UPDATE_PAYMENT_PLANS_TABLE_FILTER_STATE',
    CLEAR_PAYMENT_PLANS_STATE_ALL_TABLE_FILTERS = '@@paymentPlans/CLEAR_PAYMENT_PLANS_STATE_ALL_TABLE_FILTERS',
    CLEAR_PAYMENT_PLANS_STATE_DATA = '@@paymentPlans/CLEAR_PAYMENT_PLANS_STATE_DATA',

    SET_PAYMENT_PLAN_SELECTED_ID_REQUEST = '@@paymentPlans/SET_PAYMENT_PLAN_SELECTED_ID_REQUEST',
    SET_PAYMENT_PLAN_SELECTED_ID_SUCCESS = '@@paymentPlans/SET_PAYMENT_PLAN_SELECTED_ID_SUCCESS',
    GET_PAYMENT_PLAN_DATA_REQUEST = '@@paymentPlans/GET_PAYMENT_PLAN_DATA_REQUEST',
    GET_PAYMENT_PLAN_DATA_SUCCESS = '@@paymentPlans/GET_PAYMENT_PLAN_DATA_SUCCESS',
    GET_PAYMENT_PLAN_DATA_ERROR = '@@paymentPlans/GET_PAYMENT_PLAN_DATA_ERROR',
    CLEAR_PAYMENT_PLAN_DATA_SUCCESS = '@@paymentPlans/CLEAR_PAYMENT_PLAN_DATA_SUCCESS',

    GET_PAYMENT_PLAN_INVOICES_REQUEST = '@@paymentPlans/GET_PAYMENT_PLAN_INVOICES_REQUEST',
    GET_PAYMENT_PLAN_INVOICES_SUCCESS = '@@paymentPlans/GET_PAYMENT_PLAN_INVOICES_SUCCESS',
    GET_PAYMENT_PLAN_INVOICES_ERROR = '@@paymentPlans/GET_PAYMENT_PLAN_INVOICES_ERROR',
    UPDATE_PAYMENT_PLAN_INVOICES_FILTERS = '@@paymentPlans/UPDATE_PAYMENT_PLAN_INVOICES_FILTERS',
    UPDATE_PAYMENT_PLAN_INVOICES_SORT_BY_AND_STATE = '@@paymentPlans/UPDATE_PAYMENT_PLAN_INVOICES_SORT_BY_AND_STATE',
    UPDATE_PAYMENT_PLAN_INVOICES_TABLE_FILTER_STATE = '@@paymentPlans/UPDATE_PAYMENT_PLAN_INVOICES_TABLE_FILTER_STATE',

    GET_PAYMENT_PLAN_CONVERSATION_REQUEST = '@@paymentPlans/GET_PAYMENT_PLAN_CONVERSATION_REQUEST',
    GET_PAYMENT_PLAN_CONVERSATION_SUCCESS = '@@paymentPlans/GET_PAYMENT_PLAN_CONVERSATION_SUCCESS',
    GET_PAYMENT_PLAN_CONVERSATION_ERROR = '@@paymentPlans/GET_PAYMENT_PLAN_CONVERSATION_ERROR',
    UPDATE_PAYMENT_PLAN_CONVERSATION_FILTERS = '@@paymentPlans/UPDATE_PAYMENT_PLAN_CONVERSATION_FILTERS',
    UPDATE_PAYMENT_PLAN_CONVERSATION_TABLE_FILTER = '@@paymentPlans/UPDATE_PAYMENT_PLAN_CONVERSATION_TABLE_FILTER',
    UPDATE_PAYMENT_PLAN_CONVERSATION_SORT_BY = '@@paymentPlans/UPDATE_PAYMENT_PLAN_CONVERSATION_SORT_BY',

    PAYMENT_PLAN_ADD_COMMENT_REQUEST = '@@paymentPlans/PAYMENT_PLAN_ADD_COMMENT_REQUEST',
    UPDATE_PAYMENT_PLAN_SCHEDULE = '@@paymentPlans/UPDATE_PAYMENT_PLAN_SCHEDULE',


    GET_PAYMENT_PLAN_DATA_SCHEDULE_REQUEST = '@@paymentPlans/GET_PAYMENT_PLAN_DATA_SCHEDULE_REQUEST',
    GET_PAYMENT_PLAN_DATA_SCHEDULE_SUCCESS = '@@paymentPlans/GET_PAYMENT_PLAN_DATA_SCHEDULE_SUCCESS',
    GET_PAYMENT_PLAN_DATA_SCHEDULE_ERROR = '@@paymentPlans/GET_PAYMENT_PLAN_DATA_SCHEDULE_ERROR',
    UPDATE_PAYMENT_PLAN_DATA_SCHEDULE_FILTERS = '@@paymentPlans/UPDATE_PAYMENT_PLAN_DATA_SCHEDULE_FILTERS',
    UPDATE_PAYMENT_PLAN_DATA_SCHEDULE_SORT_BY_AND_STATE = '@@paymentPlans/UPDATE_PAYMENT_PLAN_DATA_SCHEDULE_SORT_BY_AND_STATE',

    CREATE_PAYMENT_PLAN = '@@paymentPlans/CREATE_PAYMENT_PLAN',
    FETCH_PAYMENT_PLAN_COMMUNICATION_DETAILS_PUBLIC = '@@paymentPlans/FETCH_PAYMENT_SCHEDULE_DETAILS_PUBLIC',
    FETCH_PAYMENT_PLAN_CONFIGURATIONS = '@@paymentPlans/FETCH_PAYMENT_PLAN_CONFIGURATIONS',

    ACTION_PAYMENT_PLAN_REQUEST = '@@paymentPlans/ACTION_PAYMENT_SCHEDULE_REQUEST',
    ACTION_ORGANISATION_PAYMENT_PLAN_REQUEST = '@@paymentPlans/ACTION_ORGANISATION_PAYMENT_PLAN_REQUEST',
    CANCEL_PAYMENT_PLAN_REQUEST = '@@paymentPlans/CANCEL_PAYMENT_SCHEDULE_REQUEST',
    CHECK_PAYMENT_PLANS_ACTION_STATE = '@@paymentPlans/CHECK_PAYMENT_PLANS_ACTION_STATE',
    ACTION_PAYMENT_PLANS_REQUEST = '@@paymentPlans/ACTION_PAYMENT_PLANS_REQUEST',
    ACTION_ORGANISATION_PAYMENT_PLANS_REQUEST = '@@paymentPlans/ACTION_ORGANISATION_PAYMENT_PLANS_REQUEST',

    ACTION_MANUAL_CREATE_NEW_PAYMENT_PLAN_REQUEST = '@@paymentPlans/ACTION_MANUAL_CREATE_NEW_PAYMENT_PLAN_REQUEST',

    RESEND_PAYMENT_PLAN_APPROVAL_REQUEST = '@@paymentPlans/RESEND_PAYMENT_PLAN_APPROVAL_REQUEST',

    GET_PAYMENT_PLAN_CONFIG_REQUEST = '@@paymentPlans/GET_PAYMENT_PLAN_CONFIG_REQUEST',
    GET_PAYMENT_PLAN_CONFIG_SUCCESS = '@@paymentPlans/GET_PAYMENT_PLAN_CONFIG_SUCCESS',
    GET_PAYMENT_PLAN_CONFIG_ERROR = '@@paymentPlans/GET_PAYMENT_PLAN_CONFIG_ERROR',
    CREATE_PAYMENT_PLAN_CONFIG_REQUEST = '@@paymentPlans/CREATE_PAYMENT_PLAN_CONFIG_REQUEST',
    CREATE_PAYMENT_PLAN_CONFIG_SUCCESS = '@@paymentPlans/CREATE_PAYMENT_PLAN_CONFIG_SUCCESS',
    CREATE_PAYMENT_PLAN_CONFIG_ERROR = '@@paymentPlans/CREATE_PAYMENT_PLAN_CONFIG_ERROR'
}

export interface PaymentPlansState {
    readonly loading: boolean;
    readonly savePaymentPlanDataLoading: boolean;
    readonly errorMessages: string[];
    readonly data: PaymentPlan[];
    readonly pageData: PageData;
    readonly filters: GetPaymentPlansRequestPayload['filters'];
    readonly paymentPlanState: string;
    readonly sortBy: GetPaymentPlansRequestPayload['sortBy'];
    readonly sortAscending: boolean;
    readonly tableFilter: string | undefined;
    readonly activeData: {
        loading: boolean;
        record: {};
        conversation: ConversationState;
        selectedId: string | null;
        errorMessages: string[];
        invoices: {
            readonly loading: boolean;
            readonly errorMessages: string[];
            readonly data: Invoice[];
            readonly pageData: PageData;
            readonly filters: GetInvoicesRequestPayload['filters'];
            readonly sortBy: GetInvoicesRequestPayload['sortBy'];
            readonly sortAscending: boolean;
            readonly invoiceState: string;
        };
        schedule: {
            readonly loading: boolean;
            readonly errorMessages: string[];
            readonly data: PaymentPlanSchedule[];
            readonly pageData: PageData;
            readonly filters: GetPaymentPlanDataScheduleRequestPayload['filters'];
            readonly sortBy: GetPaymentPlanDataScheduleRequestPayload['sortBy'];
            readonly sortAscending: boolean;
        };
    };
    readonly paymentPlanConfigs: PaymentPlanConfig[];
}

export interface PaymentPlan extends DynamicObject {
    readonly Id: string;
    readonly Number: string;
    readonly TotalAmount: number;
    readonly AmountRemaining: number;
    readonly RequestedDateTime: string;
    readonly LocalRequestedDateTime: string;
    readonly ApprovedDateTime: string;
    readonly LocalApprovedDateTime: string;
    readonly ClosedDateTime: string;
    readonly LocalClosedDateTime: string;
    readonly CustomerId: string;
    readonly CompanyId: string;
    readonly InvoiceIds: string[];
    readonly State: string;
    readonly TotalSchedules: number;
    readonly Frequency: string;
    readonly DepositAmount?: number;
    readonly PaymentPlanSchedules: PaymentPlanSchedule[];
    readonly ConversationLine: ConversationLine;
}

export interface GetPaymentPlansRequestPayload {
    filters: {};
    paymentPlanState: string;
    tableFilter?: string;
    sortBy: typeof paymentPlansSortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
}

export interface GetConversationRequestPayload {
    filters: {};
    sortBy: string;
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    conversationTableFilter: string;
}

export interface ConversationState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: Conversation[];
    readonly pageData: PageData;
    readonly filters: GetConversationRequestPayload['filters'];
    readonly conversationTableFilter: string;
    readonly sortBy: GetConversationRequestPayload['sortBy'];
    readonly sortAscending: boolean;
}

export interface Conversation { }

export interface PaymentPlanAddCommentRequestPayload
    extends AddCommentCommonFilterPayload {
    paymentPlanIds: string[] | number[];
    excludePaymentPlans: boolean;
}

export interface CancelPaymentPlanRequestPayload {
    filter: DynamicObject;
    paymentPlanIds: string[];
    excludePaymentPlans: boolean;
    comment: string;
    notifyCustomerCancellation: boolean;
    selectedContact?: number;
}

export interface CheckPaymentPlansActionStateRequestPayload
    extends DynamicObject {
    filters: {};
    sortBy: String;
    sortAscending: Boolean;
    pageSize: number;
    currentPage: number;
    paymentPlanIds: string[] | number[];
    excludePaymentPlans: boolean;
    callback?: (response: any) => void;
}

export interface UpdatePaymentPlanScheduleRequestPayload{
    InstalmentPayments: PaymentPlanSchedule[];
}

/**
 * Payment schedule
 */
export interface PaymentPlanSchedule {
    Id: string;
    Number: number;
    PaymentPlanId: string;
    State: string;
    TotalAmount: number;
    AmountRemaining: number;
    LocalDueDateTime: string;
    DueDateTime: string;
    LocalClosedDateTime: string;
    ClosedDateTime: string;
    CompanyId: string;
}

export interface PaymentPlanScheduleVM extends PaymentPlanSchedule {
    PaidAmount: number;
}

export interface GetPaymentPlanDataScheduleRequestPayload
    extends DynamicObject {
    filters: {};
    invoiceState: string;
    sortBy: typeof paymentScheduleSortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    callback?: (response: any) => void;
}

export interface RequestPaymentPlansPayload {
    CompanyId: string;
    InvoiceIds: string[];
    CustomerId: string;
    CreditIds: string[];
    NotificationType: string;
    callback?: (response: PaymentPlan[]) => void;
}

export interface RequestManualCreateNewPaymentPlanPayload
{
    InvoiceIds: (string | undefined)[];
    InstalmentPayments: InstalmentPayment[];
    DepositPercentage?: number;
    DepositAmount?: number;
    DepositDueDate?: string;

    callback?: (response: PaymentPlan[]) => void;
}

export interface InstalmentPayment {
    Number: number | undefined;
    TotalAmount: number | undefined;
    LocalDueDateTime: string | undefined;
}

export interface PaymentPlanInvoice {
    readonly Invoice: Invoice;
    readonly Number: string;
    readonly InvoiceCode: string;
    readonly CustomerId: string;
    readonly AmountOwing: number;
}

export interface ResendPaymentPlanApprovalRequestPayload {
    filter: DynamicObject;
    paymentPlanIds: string[];
    excludePaymentPlans: boolean;
    selectedContact?: number;
    callback?: (response: any) => void;
}

/**
 * Payment plan config
 */
export interface PaymentPlanConfig {
    ConfigId: string
    paymentPlanKeyIndex?: number,
    Frequency?: string
    Instalments: number
    CustomFields?: CustomField[]
    DepositAmount?: number
    DepositPercentage?: number
    DepositGap?: number
    ScheduleDates?: string[] | null
    NewVersion: boolean
    Name: string
    InstalmentType: string
    StartTiming?: string
    StartDelay?: number
    FrequencyGap?: number
    SpecificPaymentDate?: number
    CutoffDate?: string
    PayPercent?: number[]
    SplitInstalmentDateRange?: string[]
    ScheduleDatesDisplay?: string[]
    InstalmentAmount?: number
    IsTemplate: boolean
    IsDefault?: boolean
    AddPayPercent?: boolean
    PayPercentTotal?: number
    PayPercentWarning?: string
    PayPercentHidePreview?: boolean
    PayPercentScheduleDatesDisplay?: string[]
    InstalmentMin?: number
    InstalmentMax?: number
}
