import { Button, Card, Col, Form, InputNumber, Modal, Row, Select, Spin, Typography } from 'antd';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import QueueAnim from 'rc-queue-anim';
import ModalWithSpinner from '../../components/common/ModalWithSpinner';
import {
    confirmModalCancelText,
    confirmModalOkText
} from '../../config/config';
import { findIndex, get, includes, isEmpty, map } from 'lodash';
import { getRolePermissions } from '../../store/roles/sagas';
import { ApplicationState } from '../../store';
import { CompaniesState, CompanyUserRole, WorkflowOption } from '../../store/companies/types';
import { DynamicObject } from '../../utils/commonInterfaces';
import React, { useEffect, useState } from 'react';
import {
    getWorkflowDefinitionRequestAction,
    saveWorkflowDefinitionConfigurationRequestAction,
    clearWorkflowsStateAction,
    setWorkflowSelectedIdSuccessAction,
    clearWorkflowActiveDataAction,
} from '../../store/workflow/actions';
import { GetWorkflowDefinitionRequestPayload, WorkflowDefinition, WorkflowTimingDefinition } from '../../store/workflow/types';
import { limitOnlyNumber, getTranslatedText } from '../../utils/commonFunctions';
import { WorkflowsState } from '../../store/workflow/types';
import { getWorflowsState } from '../../store/workflow/sagas';
import { v4 as uuidv4 } from 'uuid';
import { getFullWorkflowOptions } from '../../store/companies/sagas';
import './workflows.less';

const { Title } = Typography;
const { confirm } = Modal;
const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    form: DynamicObject;
    history: {
        push: (path: string) => void;
    };
}

const formFieldNames = {
    WorkflowId: 'WorkflowId'
};

const WorkflowCustomizationManagementPage: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldsValue } = props.form;
    
    const companiesState: CompaniesState = useSelector(
        (state: ApplicationState) => state.companies
    );

    const selectedUserCompany: CompanyUserRole | undefined =
        companiesState.selectedUserCompany;
    const userRole = get(selectedUserCompany, 'Role.Name');
    const rolePermissions = useSelector(getRolePermissions);
    const workflowOptionsLoading = useSelector((state: ApplicationState) =>
        get(state.companies, getTranslatedText('loading'))
    );
    const [formHasChanged, setFormHasChanged] = useState(false);
    const workflowOptions: WorkflowOption[] = useSelector(getFullWorkflowOptions);
    const [workflowTimingDefinitions, setWorkflowTimingDefinitions] = useState<WorkflowTimingDefinition[]>([]);
    const workflowsState: WorkflowsState = useSelector(getWorflowsState);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const { loading: workflowDefinitionLoading, selectedId, record } = workflowsState.activeData;
    const workflowDefinition = record as WorkflowDefinition | undefined;

    /**
     * Function called when dropdown filter value changes.
     */
    const onChangeWorkflow = (selectedWorkflowId: string) => {
        if (isEmpty(selectedWorkflowId)) return;

        dispatch(setWorkflowSelectedIdSuccessAction(selectedWorkflowId));
        setFormHasChanged(false);
        fetchWorkflowDefinition(selectedWorkflowId);
    };

    useEffect(() => {
        let workflowTimingDefinitions: WorkflowTimingDefinition[] = [];

        if (workflowDefinition) {
            workflowDefinition.States.forEach(state => {
                if (state.Transitions && state.Transitions.length > 0) {
                    state.Transitions.forEach((transition) => {
                        if (transition.Timing) {
                            const workflowTimingDefinition: WorkflowTimingDefinition = {
                                Id: uuidv4(),
                                Name: state.Name,
                                DisplayName: transition.DisplayName,
                                Trigger: transition.Trigger,
                                NewState: transition.NewState,
                                Gap: transition.Timing.Gap,
                                Orientation: transition.Timing.Orientation
                            }

                            workflowTimingDefinitions.push(workflowTimingDefinition);
                        }
                    })
                }
            });
        }

        setWorkflowTimingDefinitions(workflowTimingDefinitions);
    }, [workflowDefinition]);

    const initializeWorkflowData = () => {
        if (selectedUserCompany) {
            resetFields();
            dispatch(clearWorkflowsStateAction());
        }
    };

    useEffect(initializeWorkflowData, [selectedUserCompany]);

    /**
     * Fetch company workflow definition configuration
     */
    const fetchWorkflowDefinition = (selectedWorkflowId: string | null) => {
        if (isEmpty(selectedWorkflowId)) return;

        if (selectedWorkflowId) {
            const request: GetWorkflowDefinitionRequestPayload = {
                WorkflowId: selectedWorkflowId
            };

            dispatch(getWorkflowDefinitionRequestAction(request));
        }
    };

    /**
     * Function called when `Cancel` button is clicked inside Customer payment options.
     * Pops up a confirmation modal asking to revert all changes made.
     */
    const onCancelButtonClick = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText("Continue"),
            content: (
                <div>{getTranslatedText(`When you click the ${confirmModalOkText} button, all the data will be reverted to the last saved values`).split(/(<b>Yes<\/b>)/g) // Split around the <b>...</b> tags
                    .map((part, index) =>
                        part === "<b>Yes</b>" ? ( // Match exactly <b>Yes</b>
                            <b key={index}>Yes</b> // Render "Yes" as bold
                        ) : (
                            part // Render regular text
                        )
                    )}</div>
            ),
            onOk() {
                resetFields();
                dispatch(clearWorkflowActiveDataAction());
                setFormHasChanged(false);
            },
            onCancel() { },
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
        });
    };

    /**
     * Function called when `Save` button is clicked and will send all the changes to API.
     */
    const onSaveButtonClick = () => {
        validateFields((err: DynamicObject, values: DynamicObject) => {
            if (!err) {
                setSubmitLoading(true);
                const payload = {
                    WorkflowId: selectedId,
                    WorkflowTransitionDefinitions: workflowTimingDefinitions,
                };

                dispatch(
                    saveWorkflowDefinitionConfigurationRequestAction(
                        payload,
                        ({ IsSuccess }: { IsSuccess: boolean }) =>
                            handleModalSaveResponse(IsSuccess)
                    )
                );
            }
        });
    };

    /**
     * Function responsible for showing the success/error modal after saving the changes made.
     * @param IsSuccess - if the saving of data is successful
     * @param lastSavedPayload - latest saved values
     */
    const handleModalSaveResponse = (IsSuccess: boolean) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            setFormHasChanged(false);
            Modal.success({
                title: getTranslatedText("Success"),
                content: getTranslatedText("Workflow Customisation settings saved successfully"),
                onOk: () => {
                    fetchWorkflowDefinition(selectedId);
                },
                okText: getTranslatedText("OK")
            });
        } else {
            Modal.error({
                title: getTranslatedText("Error"),
                content: getTranslatedText("Failed to save workflow customisation settings"),
                okText: getTranslatedText("OK")
            });
        }
    };


    /**
     * Function for update workflow timing definition
     */
    const UpdateWorkflowTimingDefinition = (workflowTimingDefinition: WorkflowTimingDefinition, newWorkflowTimingDefinition: WorkflowTimingDefinition) => {
        setWorkflowTimingDefinitions(list => {
            const updatedInvoiceIdx = findIndex(list, wf => wf === workflowTimingDefinition);
            if (updatedInvoiceIdx !== -1) {
                list.splice(updatedInvoiceIdx, 1, newWorkflowTimingDefinition);
            }

            return [...list];
        });

        if (workflowTimingDefinition !== newWorkflowTimingDefinition) {
            setFormHasChanged(true);
        }
        else if (workflowTimingDefinition === newWorkflowTimingDefinition) {
            setFormHasChanged(false);
        }
    };

    const handleChange = (workflowTimingDefinition: WorkflowTimingDefinition) => (value: number) => {
        const newWorkflowTimingDefinition: WorkflowTimingDefinition = {
            ...workflowTimingDefinition,
            Gap: (value || 0)
        };

        UpdateWorkflowTimingDefinition(workflowTimingDefinition, newWorkflowTimingDefinition);
    }

    /**
     * Populate the workflow timing
     */
    const populateWorkflowTimings = (workflowTimingDefinitions: WorkflowTimingDefinition[]) => {
        if (!selectedId) {
            return null;
        }

        if (workflowTimingDefinitions && workflowTimingDefinitions.length > 0) {
            return map(workflowTimingDefinitions, (workflowTimingDefinition) => (
                <Col key={workflowTimingDefinition.Id} span={8} style={{ padding: '8px' }}>
                    <Card title={workflowTimingDefinition.Name}>
                        <p>{getTranslatedText("Trigger")}: {workflowTimingDefinition.DisplayName ? workflowTimingDefinition.DisplayName : workflowTimingDefinition.Trigger}</p>
                        <p>{getTranslatedText("New State")}: {workflowTimingDefinition.NewState}</p>
                        <div>
                            {getTranslatedText("Gap")}: 
                            <InputNumber
                                id={workflowTimingDefinition.Id}
                                value={workflowTimingDefinition.Gap}
                                onChange={(value) => {
                                    handleChange(workflowTimingDefinition)(value || 0);
                                }}
                                placeholder={getTranslatedText("Input timing gap")}
                                onKeyDown={limitOnlyNumber()}
                                style={{ margin: '0px 8px' }}
                                step={1}
                                min={-31}
                                max={31}
                            />
                            {getTranslatedText("for")} {workflowTimingDefinition.Orientation}
                        </div>
                    </Card>
                </Col>
            ));
        }

        if(!workflowDefinitionLoading && (!workflowTimingDefinitions || workflowTimingDefinitions.length === 0)) {
            return (<p style={{ padding: '20px 0', width: 'auto', textAlign: 'center', fontWeight: 'bold' }}>{getTranslatedText("No workflow definition found")}</p>)
        }
    };

    const allowedRoles = rolePermissions.CUSTOMIZATION_UPDATE;
    const formDisabled = !isEmpty(allowedRoles) && !includes(allowedRoles, userRole);

    return (
        <div className="h-100">
            <Col span={24}>
                <Form
                    className="form-inline-mb-0"
                    labelCol={{
                        xxl: { span: 7 },
                        xl: { span: 7 },
                        lg: { span: 6 },
                        md: { span: 6 },
                        sm: { span: 6 },
                        xs: { span: 6 },
                    }}
                    wrapperCol={{
                        xxl: { span: 17 },
                        xl: { span: 17 },
                        lg: { span: 18 },
                        md: { span: 18 },
                        sm: { span: 18 },
                        xs: { span: 18 },
                    }}
                >
                    <QueueAnim type={['right', 'left']} leaveReverse>
                        <Row key="title-container">
                            <Col span={24}>
                                <Title level={3}>{getTranslatedText("Workflow Customisation")}</Title>
                            </Col>
                        </Row>
                        <div className="spacer-15" />
                        <div className="h-100">
                            <Col span={24}>
                                <QueueAnim type={['right', 'left']} leaveReverse>
                                    <Row align="middle">
                                        <Col md={16} xs={24}>
                                            <Row>
                                                <Col style={{ minWidth: 250}}>
                                                    <FormItem 
                                                        label={getTranslatedText("Workflow")}
                                                        labelAlign='left' 
                                                        labelCol={{
                                                            xxl: { span: 2 },
                                                            xl: { span: 3 },
                                                            lg: { span: 3 },
                                                            md: { span: 3 },
                                                            sm: { span: 3 },
                                                            xs: { span: 3 },
                                                        }}
                                                    >
                                                        {getFieldDecorator(formFieldNames.WorkflowId, {
                                                            initialValue: selectedId,
                                                            rules: [],
                                                        })(
                                                            <Select
                                                                style={{ width: '40%' }}
                                                                loading={workflowOptionsLoading}
                                                                disabled={workflowOptionsLoading}
                                                                placeholder={getTranslatedText("Choose a workflow")}
                                                                onChange={onChangeWorkflow}
                                                            >
                                                                {map(
                                                                    workflowOptions,
                                                                    ({ WorkflowId, WorkflowName }) => (
                                                                        <Option key={WorkflowId} value={WorkflowId}>
                                                                            {WorkflowName}
                                                                        </Option>
                                                                    )
                                                                )}
                                                            </Select>
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8} xs={24} className="ta-right">
                                            <Button
                                                className="mr-10 w-100px"
                                                type="primary"
                                                onClick={onSaveButtonClick}
                                                disabled={formDisabled || !formHasChanged}
                                                loading={submitLoading}
                                            >
                                                {getTranslatedText("Save")}
                                            </Button>
                                            <Button
                                                className="buttonGrey w-100px"
                                                onClick={onCancelButtonClick}
                                                disabled={formDisabled || !formHasChanged}
                                            >
                                                {getTranslatedText("Cancel")}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <div className="spacer-15" />
                                    <Row align="middle">
                                        <Spin tip={getTranslatedText("Loading workflow definition")} wrapperClassName="spinner-wh100" spinning={workflowDefinitionLoading} style={{display: 'inherit'}}>
                                            {populateWorkflowTimings(workflowTimingDefinitions)}
                                        </Spin>
                                    </Row>
                                </QueueAnim>
                            </Col>
                        </div>
                    </QueueAnim>
                </Form>
                {submitLoading && (
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Saving customisation data")}
                        modalVisible={submitLoading}
                        displayMessage={getTranslatedText("Please wait while saving customisation data")}
                    />
                )}
            </Col>
        </div>
    );
};

const WorkflowCustomizationManagementPageForm = Form.create({
    name: 'workflow-customisation-management-page-form',
})(WorkflowCustomizationManagementPage);
export default withRouter(WorkflowCustomizationManagementPageForm);