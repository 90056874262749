/**
 * File responsible for the content when clicking `Allocate to invoice` in credits management pages.
 */

import { Button, Col, DatePicker, Form, Modal, Row } from 'antd';
import { isEmpty, map, get } from 'lodash';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    dateFormatDDMMMYYYYSpace,
    dateFormatYYYYMMDDDash,
} from '../../constants/dateFormats';
import { getPopoverContainer, getTranslatedText } from '../../utils/commonFunctions';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import moment from 'moment-timezone';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import { PAGE_NAMES_FOR_VIEW } from '../../config/tableAndPageConstants';
import { creditReconciliationReportQuery, creditReconciliationReportQueryName } from '../../constants/downloadToExcel';
import CreateScheduledReportDrawer from '../common/CreateScheduledReportDrawer';


const ModalWithSpinner = lazy(() => import('../common/ModalWithSpinner'));

const { Item: FormItem } = Form;

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly dispatchAction: (payload: any) => void;
    readonly formatDateToDateObjectUTC: (
        date: any,
        fromFormat?: string | null
    ) => any;
}

const CreditReconcileReportPanel: React.FC<IProps> = ({
    containerRef,
    visible,
    closePanel,
    form,
    dispatchAction,
    formatDateToDateObjectUTC,
}: IProps) => {
    const dispatch = useDispatch();
    
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [createScheduleReportPayload, setCreateScheduleReportPayload] = useState<any>();
    const [displayCreateSchedulePanel, setdisplayCreateSchedulePanel] = useState<boolean>(false);
    const {
        getFieldDecorator,
        validateFields,
        resetFields,
        getFieldValue,
    } = form;

    /**
     * Function called when `Cancel` button is clicked inside Reconcile report panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                const startDateLocal =
                    moment(get(values, 'StartDate')).format(
                        dateFormatYYYYMMDDDash
                    ) + 'T00:00:00';
                const endDateLocal =
                    moment(get(values, 'EndDate')).format(
                        dateFormatYYYYMMDDDash
                    ) + 'T23:59:59';

                const startDate = formatDateToDateObjectUTC(startDateLocal);
                const endDate = formatDateToDateObjectUTC(endDateLocal);

                if (moment(endDate).isBefore(moment(startDate))) {
                    Modal.error({
                        title: getTranslatedText('Error'),
                        content: (
                            <div>
                                {getTranslatedText('<b>Start date</b> should not be greater than <b>End date</b>!').split(/(<b>[^<]+<\/b>)/g) // Split by the <b>...</b> tags
                                    .map((part, index) =>
                                        part.startsWith('<b>') ? (
                                            <b key={index}>{part.replace(/<\/?b>/g, '')}</b> // Render bold part
                                        ) : (
                                            part // Render regular text
                                        )
                                    )}
                            </div>
                        ),
                        okText: getTranslatedText('OK'),
                    });
                } else {
                    setSubmitLoading(true);
                    dispatch(
                        dispatchAction({
                            startDate,
                            endDate,
                            callback: reconcileReportResponseModal,
                        })
                    );
                }
            }
        });
    };

    /**
     * Function called when start downloading the complete report click.
     */
    const handleCreateScheduledReportForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                setdisplayCreateSchedulePanel(true);
                const startDateLocal =
                    moment(get(values, 'StartDate')).format(
                        dateFormatYYYYMMDDDash
                    ) + 'T00:00:00';
                const endDateLocal =
                    moment(get(values, 'EndDate')).format(
                        dateFormatYYYYMMDDDash
                    ) + 'T23:59:59';

                const startDate = formatDateToDateObjectUTC(startDateLocal);
                const endDate = formatDateToDateObjectUTC(endDateLocal);

                if (moment(endDate).isBefore(moment(startDate))) {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <div>
                                <b>Start date</b> should not be greater than{' '}
                                <b>End date</b>!
                            </div>
                        ),
                    });
                } else {
                    const variables = {
                        CreatedDateTimeMin: startDate,
                        CreatedDateTimeMax: endDate,
                    };

                    const payload = {
                        Query: creditReconciliationReportQuery,
                        OperationName: creditReconciliationReportQueryName,
                        Variables: JSON.stringify(variables),
                        PageName: 'CreditReconciliationPage',
                        title: 'Credit Reconciliation',
                        Type: 1
                    };

                    setCreateScheduleReportPayload(payload);
                }
            }
        });
    };

    /**
     * Function called when create schedule report is closed.
     * @param resetChanges
     * @param saveChanges
     * @param containerRef
     */
    const onCreateScheduledReportEnd = (
        containerRef?: any
    ) => {
        setdisplayCreateSchedulePanel(false);
        if (closePanel) closePanel(true);
    };

    /**
     * Function responsible for showing the response modal after reconciling the report.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const reconcileReportResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content:
                    getTranslatedText('Report generated successfully. You will be notified once it is finished together with the download link!'),
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK'),
            });
        } else {
            let errorMessageContent: any = getTranslatedText(`Failed to generate the report!`);
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK'),
            });
        }
    };

    /**
     * Function that restricts the dates that can be selected by end date
     * @param dateValue
     */
    const restrictEndDate = (dateValue: any) => {
        return moment(moment(dateValue)).isBefore(
            moment(moment(getFieldValue('StartDate')))
        );
    };

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {
        const startDateUsed = moment().subtract(7, 'days');
        return (
            <Form className="form-inline-mb-0" labelCol={{ span: 8 }}>
                <Row>
                    <Col className="mb-10">
                        <FormItem label={getTranslatedText("Start date")}>
                            {getFieldDecorator('StartDate', {
                                initialValue: moment(startDateUsed),
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText('Start date required!'),
                                    },
                                ],
                            })(
                                <DatePicker
                                    format={dateFormatDDMMMYYYYSpace}
                                    placeholder={getTranslatedText("Start")}
                                    style={{ width: 300 }}
                                />
                            )}
                        </FormItem>
                    </Col>
                    <Col>
                        <FormItem label={getTranslatedText("End date")}>
                            {getFieldDecorator('EndDate', {
                                initialValue: moment(),
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText('End date required!'),
                                    },
                                ],
                            })(
                                <DatePicker
                                    format={dateFormatDDMMMYYYYSpace}
                                    placeholder={getTranslatedText("End")}
                                    disabledDate={restrictEndDate}
                                    style={{ width: 300 }}
                                />
                            )}
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                    <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleCreateScheduledReportForm}
                        >
                            {getTranslatedText('Create Scheduled Report')}
                        </Button>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                        >
                            {getTranslatedText('Download')}
                        </Button>
                        <Button onClick={handleClosePanel}>{getTranslatedText('Cancel')}</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Generating report")}
                        modalVisible={submitLoading}
                        displayMessage={getTranslatedText("Please wait while we start the data extraction process. . .")}
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
             <CreateScheduledReportDrawer
                        visible={displayCreateSchedulePanel}
                        onClose={onCreateScheduledReportEnd}
                        widgetQuery={createScheduleReportPayload}
             />
        </Row>
    );
};

const CreditReconcileReportPanelForm = Form.create({
    name: 'reconcile-report-panel-form',
})(CreditReconcileReportPanel);

export default withRouter(
    withNumberFormatHandler(
        withDateFormatHandler(CreditReconcileReportPanelForm)
    )
);
