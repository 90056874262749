/**
 * File for `Communication Delivery's` widget.
 */

import { Spin, Table } from 'antd';
import {
    clone,
    filter,
    forEach,
    get,
    includes,
    isNumber,
    isUndefined,
    map,
} from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    TooltipFormatter,
    XAxis,
    YAxis,
} from 'recharts';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import {
    widgetDisplayTypeValues,
    widgetDateRangeValues,
    tableNumberFormatter,
    populatePayloadForOrganisationRegionalWidgets
} from '../../constants/dashboards';
import { dateFormatYYYYMMDDDash } from '../../constants/dateFormats';
import { getDashboardCommunicationDeliveryRequestAction } from '../../store/dashboards/actions';
import { dashboardBypassAPIFetch, getTranslatedText } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    appliedFilterIndicator,
    customFieldIndicator,
} from '../common/FilterBar';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import {
    defaultEndDate,
    defaultStartDate,
    displayColumnIndicator,
    displayColumnOptions,
} from './CommunicationDeliveryWidgetFields';
import { ApplicationState } from '../../store';

interface IProps {
    widgetDetails: DynamicObject;
    readonly formatNumber: (amount: number) => JSX.Element;
    preview?: boolean;
    isOrgView?: boolean;
    readonly functionRefObj?: any;
}

const CommunicationDeliveryWidget: React.FC<IProps> = ({
    widgetDetails,
    formatNumber,
    preview,
    isOrgView,
    functionRefObj
}: IProps) => {
    const unmountedRef = useRef<any>(null);
    const dispatch = useDispatch();

    const initialTableColumns = filter(displayColumnOptions, [
        'defaultChecked',
        true,
    ]).map(({ label, value }: DynamicObject) => ({
        title: getTranslatedText(label),
        dataIndex: value,
    }));

    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );

    const [widgetState, setTableState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        dataSource: DynamicObject[];
        columns: DynamicObject[];
        chartMarginLeft: number;
        tickWidth: number;
    }>({
        lastWidgetDetails: {},
        loading: false,
        dataSource: [],
        columns: initialTableColumns,
        chartMarginLeft: 20,
        tickWidth: 30,
    });

    const [checkedDisplayOptions, setCheckedDisplayOptions] = useState<
        DynamicObject | undefined
    >(undefined);

    /**
     * Common function for updating the `widgetState` state.
     * @param widgetStateObject
     */
    const updateWidgetStateObject = (widgetStateObject: {}) => {
        setTableState({
            ...widgetState,
            ...widgetStateObject,
        });
    };

    interface ResultObject {
        WorkflowId: string;
        States: string[];
    }

    function convertTasksWorkflowsToObject(tasksWorkflows: string[]): ResultObject[] {
        const workflowMap: { [key: string]: string[] } = {};
        if (!isUndefined(tasksWorkflows)) {
            tasksWorkflows.forEach((workflow) => {
                const [state, workflowId] = workflow.split('---');
                if (workflowMap[workflowId]) {
                    workflowMap[workflowId].push(state);
                } else {
                    workflowMap[workflowId] = [state];
                }
            })
        };

        return Object.keys(workflowMap).map((workflowId) => ({
            WorkflowId: workflowId,
            States: workflowMap[workflowId],
        }));
    }

     /**
     * Function for getting the length of text.
     * @param text
     */
     const measureText = (text: string) => {
        return text ? text.length : 0;
    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        let customerFieldValue: string = '';
        const communicationDeliveryColumns: DynamicObject[] = [];
        let hasDisplayValue = false;
        const customFieldFilters: DynamicObject = {};
        const customFieldsUsed = [
            CUSTOM_FIELD_TYPES.CUSTOMER,
            CUSTOM_FIELD_TYPES.INVOICE,
        ];

        let WorkflowFilters: DynamicObject = {};

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            const fieldName = wdKey.replace(displayColumnIndicator, '');
            
            if (
                includes(wdKey, displayColumnIndicator) &&
                !isUndefined(wdValue)
            ) {
                hasDisplayValue = true;
                if (wdValue) {
                    const columnTitle: string = get(
                        displayColumnOptions,
                        `${fieldName}.label`,
                        ''
                    ) as string;
                    communicationDeliveryColumns.push({
                        title: columnTitle,
                        dataIndex: columnTitle,
                    });
                }
            }
            if (includes(wdKey, customFieldIndicator)) {
                const customFieldType = get(
                    wdKey.replace(customFieldIndicator, '').split('--'),
                    0
                );

                if (includes(customFieldsUsed, customFieldType)) {
                    customFieldFilters[wdKey + appliedFilterIndicator] =
                        wdValue;
                }
            }

            else if (includes(wdKey, 'Customer')) {
                customerFieldValue = wdValue;
            }
        });

        let startDate: any = clone(defaultStartDate);
        let endDate: any = clone(defaultEndDate);
        const dateRange = get(widgetDetails, 'dateRangeSelected');
        if (dateRange === widgetDateRangeValues.CUSTOM_DATE_RANGE) {
            const customStartDate =
                get(widgetDetails, 'customDateRangeStart') || defaultStartDate;
            const customEndDate =
                get(widgetDetails, 'customDateRangeEnd') || defaultEndDate;
            startDate =
                moment(customStartDate).format(dateFormatYYYYMMDDDash) +
                'T00:00:00';
            endDate =
                moment(customEndDate).format(dateFormatYYYYMMDDDash) +
                'T23:59:59';
        } else if (dateRange === widgetDateRangeValues.THIS_CALENDAR_YEAR) {
            startDate = moment().format('YYYY-01-01T00:00:00');
            endDate = moment().format('YYYY-12-31T23:59:59');
        } else if (dateRange === widgetDateRangeValues.LAST_CALENDAR_YEAR) {
            startDate = moment()
                .subtract(1, 'year')
                .format('YYYY-01-01T00:00:00');
            endDate = moment()
                .subtract(1, 'year')
                .format('YYYY-12-31T23:59:59');
        } else if (
            dateRange === widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU ||
            dateRange === widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU
        ) {
            const thisYearFinancialYearStart = moment().format(
                'YYYY-07-01T00:00:00'
            );
            const thisYearFinancialYearStartEnd = moment()
                .add(1, 'year')
                .format('YYYY-06-30T23:59:59');
            if (dateRange === widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU) {
                startDate = thisYearFinancialYearStart;
                endDate = thisYearFinancialYearStartEnd;
            } else if (
                dateRange === widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU
            ) {
                startDate = moment(thisYearFinancialYearStart)
                    .subtract(1, 'year')
                    .format(`${dateFormatYYYYMMDDDash}T00:00:00`);
                endDate = moment(thisYearFinancialYearStartEnd)
                    .subtract(1, 'year')
                    .format(`${dateFormatYYYYMMDDDash}T23:59:59`);
            }
        }

        WorkflowFilters = convertTasksWorkflowsToObject(get(widgetDetails, 'tasksWorkflows')); 
        const taskAutomation: any[] = get(widgetDetails, 'taskAutomation');
        let AutomationFilters = null;

        if (taskAutomation !== undefined){
            AutomationFilters = taskAutomation.length > 1 ? null : taskAutomation[0];
        }

        const contactType: any[] = get(widgetDetails, 'taskType');
        let ContactTypeFilters = null;

        if (contactType !== undefined){
            ContactTypeFilters = contactType.length > 1 ? null : contactType[0];
        }

        let payload: DynamicObject = {
            filters: {
                WorkflowFilters,
                Automation: AutomationFilters,
                CreatedDateMin: startDate,
                CreatedDateMax: endDate,
                ContactType: ContactTypeFilters,
                DisplayName: customerFieldValue,
                CustomerCode: customerFieldValue
            },
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationRegionalWidgets({
                payload,
                widgetDetails,
                organisationCompanies
            });
        }

        dispatch(
            getDashboardCommunicationDeliveryRequestAction(
                payload,
                isOrgView,
                (delivery: DynamicObject[]) => {
                    if (unmountedRef.current) return;

                    let usedColumns: any = [];
                    if (hasDisplayValue) {
                        usedColumns = communicationDeliveryColumns;
                    } else {
                        usedColumns = widgetState.columns;
                    }

                    const filteredColumns = usedColumns.map((item: any) => {
                        if (item.title === 'Type') {
                          return { 
                                title: 'Type',
                                dataIndex: 'StateName' 
                            };
                        } else if (item.title === 'Opened Count') {
                            return { 
                                title: 'Opened Count',
                                dataIndex: 'OpenedCount' 
                            };
                        } else if (item.title === 'Failed Count') {
                            return { 
                                title: 'Failed Count',
                                dataIndex: 'FailedCount' 
                            };
                        } else if (item.title === 'Delivered Count') {
                            return { 
                                title: 'Delivered Count',
                                dataIndex: 'DeliveredCount' 
                            };
                        } else if (item.title === 'Contact Type') {
                            return { 
                                title: 'Contact Type',
                                dataIndex: 'ContactType' 
                            };
                        }
                    });

                      let dataSource = map(delivery, (del) => {
                        const total = get(del, 'OpenedCount', 0) + get(del, 'FailedCount', 0) + get(del, 'DeliveredCount', 0);
                        return {
                            ...del,
                            'Opened Count': get(del,'OpenedCount'),
                            'Failed Count': get(del, 'FailedCount'),
                            'Delivered Count': get(del,'DeliveredCount'),
                            'Contact Type': get(del,'ContactType'),
                            Type: get(del, 'StateName'),
                            'total': total,
                        };
                    });

                    let longestWord = '';
                        map(dataSource, (ds) => {
                            let user = ds.Type

                            if (ds.total > 0 && longestWord.length < user.length) {
                                longestWord = user; 
                            }
                        })

                        const longestWordWidth = measureText(longestWord) * 8;
                        const tickWidth = longestWordWidth > 300 ? 300 : longestWordWidth;
                        const chartMarginLeft = tickWidth - 60 > 0 ? tickWidth - 60 : tickWidth;

        
                    updateWidgetStateObject({
                        dataSource: dataSource,
                        loading: false,
                        lastWidgetDetails: clone(widgetDetails),
                        columns: filteredColumns,
                        chartMarginLeft,
                        tickWidth
                    });
                },
                payloadCallback
            )
        );
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        const bypassAPIFetching = dashboardBypassAPIFetch(
            widgetState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateWidgetStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    useEffect(initializeWidgetData, [widgetDetails]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    /**
     * Function for formatting the tooltip.
     * @param value
     */
    const tooltipFormatter: TooltipFormatter = (value: any, name: string) => {
        const formattedValue = isNumber(value) ? formatNumber(value) : value;
        const translatedName = getTranslatedText(name);
        return [formattedValue, translatedName];
    };

    const customContent = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-widget-tooltip">
                    <p className="label">{payload[0].payload.StateName}</p>
                    {!isUndefined(payload[0].payload["DeliveredCount"]) && <p className="label">{getTranslatedText('Delivered')} : {`${formatNumber(payload[0].payload["DeliveredCount"])}`}</p>}
                    {!isUndefined(payload[0].payload["FailedCount"]) && <p className="label">{getTranslatedText('Failed')} : {`${formatNumber(payload[0].payload["FailedCount"])}`}</p>}
                    {!isUndefined(payload[0].payload["OpenedCount"]) && <p className="label">{getTranslatedText('Opened')} : {`${formatNumber(payload[0].payload["OpenedCount"])}`}</p>}
                    {!isUndefined(payload[0].payload["ContactType"]) && <p className="label">{getTranslatedText('Contact Type')} : {`${(payload[0].payload["ContactType"])}`}</p>}
                </div>
            );
        }

        return null;
    };

    /**
     * Function that sets the display options to be used
     */
    const initializeCheckedDisplayOptions = () => {
        let currentSelectedDisplayOptions: DynamicObject = {};
        forEach(displayColumnOptions, (ngo: DynamicObject) => {
            const fieldValue = get(ngo, 'value');
            const displayFilter = `${displayColumnIndicator}${fieldValue}`;
            const displayOption = get(widgetDetails, displayFilter);
            
            let isChecked = false;
            if (!isUndefined(displayOption)) {
                isChecked = displayOption;
            } else {
                isChecked = get(ngo, 'defaultChecked');
            }

            if (isChecked) {
                currentSelectedDisplayOptions[fieldValue] = ngo;
            }
        });

        setCheckedDisplayOptions(currentSelectedDisplayOptions);
    };

    useEffect(initializeCheckedDisplayOptions, [widgetDetails]);


    /**
     * Function for populating the bar items for chart.
     */
    const populateBarItemsForChart = () => {    
        return filter(checkedDisplayOptions, (cdo: DynamicObject) => cdo.value !== 'StateName')
        .map((cdo:DynamicObject) => (
            <Bar
                key={get(cdo, 'value')}
                dataKey={get(cdo, 'label')}
                fill={get(cdo, 'color')}
            />
        ));
    };

    /**
     * Function for populating the line items for chart.
     */
    const populateLineItemsForChart = () => {
        return filter(checkedDisplayOptions, (cdo: DynamicObject) => cdo.value !== 'StateName')
        .map( (cdo: DynamicObject) => (
            <Line
                key={get(cdo, 'value')}
                dataKey={get(cdo, 'value')}
                stroke={get(cdo, 'color')}
                type="monotone"
            />
        ));
    };

    const COLORS = [
        '#0088FE',
        '#00C49F',
        '#FFBB28',
        '#FF8042',
        '#F44336',
        '#9C27B0',
        '#FFEB3B',
        '#795548',
        '#8BC34A',
        '#263238',
    ];

    /**
     * Function for rendering the labels for pie chart.
     * @param props
     */
    const renderCustomizedLabel = (props: any) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            midAngle,
            outerRadius,
            StateName,
            percent,
            innerRadius,
        } = props;

        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const textAnchor = cos >= 0 ? 'start' : 'end';
        const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
        const x = cx + radius * cos;
        const y = cy + radius * sin;
        return (
            <g>
                <text
                    x={x}
                    y={y}
                    textAnchor={textAnchor}
                    fill="#333"
                    dominantBaseline="central"
                >
                    {/*{getTranslatedText(StateName)}*/}
                    {StateName}
                </text>
                <text x={x} y={y} dy={18} textAnchor={textAnchor} fill="#999">
                    {`${(percent * 100).toFixed(2)}%`}
                </text>
            </g>
        );
    };

    const populateWidgetContent = () => {
        const displayView = get(widgetDetails, 'displayType');

        if(displayView === widgetDisplayTypeValues.TABLE){
            return (
                <Table
                    rowClassName={(record) => {
                        return get(record, 'xDisplay') === 'Grand total'
                            ? 'table-total-row'
                            : '';
                    }}
                    className="table-striped-rows table-ws-nw"
                    columns={widgetState.columns.map(column => ({
                        ...column,
                        title: getTranslatedText(column.title)
                    }))}
                    dataSource={tableNumberFormatter(widgetState.dataSource, formatNumber)}
                    loading={widgetState.loading}
                    pagination={false}
                    size="middle"
                    rowKey="key"
                    locale={{
                        emptyText: getTranslatedText('No Data'),
                    }}
                />
            );
        } else if (displayView === widgetDisplayTypeValues.PIE_CHART) {      
            return (
                <Spin wrapperClassName="spinner-wh100" spinning={widgetState.loading}>
                    <ResponsiveContainer width="99%" height="99%">
                        <PieChart>
                            <Pie
                                paddingAngle={1}
                                minAngle={1}
                                data={widgetState.dataSource}
                                label={renderCustomizedLabel}
                                labelLine={true}
                                outerRadius="70%"
                                fill="#8884d8"
                                dataKey="total"
                                nameKey="StateName"
                                isAnimationActive={false}
                            >
                                {map(widgetState.dataSource, (_entry, index) => (
                                    <Cell
                                        key={index}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                            <Tooltip formatter={tooltipFormatter} content={customContent} />
                            {preview && <Legend formatter={(value) => getTranslatedText(value)} />}
                        </PieChart>
                    </ResponsiveContainer>
                </Spin>
            );
            } else if (displayView === widgetDisplayTypeValues.BAR_CHART || displayView === undefined) {
                return (
                    <Spin
                        wrapperClassName="spinner-wh100"
                        spinning={widgetState.loading}
                    >
                        <ResponsiveContainer width="99%" height="99%">
                            <BarChart
                                data={widgetState.dataSource}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: widgetState.chartMarginLeft,
                                    bottom: 15,
                                }}
                                layout="vertical"
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <YAxis 
                                    type="category"
                                    dataKey="StateName" 
                                    tick={{
                                        fontSize: 14,
                                        width: widgetState.tickWidth,
                                    }}
                                />
                                <XAxis 
                                    type="number"
                                    dataKey="total"
                                    allowDecimals={false}
                                    width={widgetState.tickWidth}
                                    orientation="bottom"
                                />
                                <Tooltip formatter={tooltipFormatter} />
                                {preview && <Legend formatter={(value) => getTranslatedText(value)} />}
                                {populateBarItemsForChart()}
                            </BarChart>
                        </ResponsiveContainer>
                        <div className="tickets-sticky-x-axis" />
                    </Spin>
            );
        } else {
            return (
                <Spin
                    wrapperClassName="spinner-wh100"
                    spinning={widgetState.loading}
                >
                    <ResponsiveContainer width="99%" height="99%">
                        <LineChart
                            data={widgetState.dataSource}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 15,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                type="category"
                                dataKey="StateName" 
                            />
                            <YAxis 
                                type="number"
                                dataKey="total"
                                allowDecimals={false}
                            />
                            <Tooltip formatter={tooltipFormatter} />
                            {preview && <Legend formatter={(value) => getTranslatedText(value)} />}
                            {populateLineItemsForChart()}
                        </LineChart>
                    </ResponsiveContainer>
                </Spin>
            );
        }
    };

    return (
        <div className="communicationDelivery-widget-container h-100">
           {populateWidgetContent()}
        </div>
    );
};

export default withNumberFormatHandler(CommunicationDeliveryWidget);
